import { useAppContext } from "context/AppContext";
import { BusinessLink } from "../business-link";
import businessApi from "assets/images/business-api.png";
import consumerModule from "assets/images/consumer-module.png";
import "./index.scss";

export const SecondaryAreaContent = () => {
  const { isConsumerView } = useAppContext();

  return (
    <div className="secondary-area-content">
      {isConsumerView ? (
        <div className="consumer-area">
          <img src={consumerModule} alt="Offers for the consumer" loading="lazy" className="consumer-image" />
        </div>
      ) : (
        <div className="business-area">
          <BusinessLink
            title="API Solutions"
            image={businessApi}
          />
        </div>
      )}
    </div>
  );
}
