import MenuArrow from "assets/images/menu-arrow.png";
import cn from "classnames";
import { useState } from "react";
import { MENU_LINKS } from "routes/paths";
import "./index.scss";

const timeout = (ms) => {
  return new Promise((res) => setTimeout(res, ms));
};

const MenuLink = ({ name, href, external }) => {
  let attrs = { href };
  if (external) {
    attrs = { target: "_blank", rel: "noreferrer noopener", href };
  }

  // Check with and without trailing slash
  const active =
    `${href}/` === document.location.pathname ||
    href === document.location.pathname;

  return !href ? (
    <div className="section-name">{name}</div>
  ) : (
    <a
      {...attrs}
      className={cn("menu-link-container", "menu-link", {
        active,
      })}
    >
      {name}
      <img src={MenuArrow} alt="menu-arrow" className="menu-arrow" />
    </a>
  );
};

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const handleClick = async () => {
    if (isOpen) {
      setModalVisible(false);
      await timeout(300);
      setIsOpen(false);
      document.body.style.overflow = "unset"; // enable page scroll
      return;
    } else {
      setIsOpen(true);
      await timeout(100);
      setModalVisible(true);
      document.body.style.overflow = "hidden"; // prevent page scroll
    }
  };

  return (
    <div className="menu-container" onClick={handleClick}>
      <h1 className={cn("title", { hidden: isOpen })}>Menu</h1>
      <div className="nav-container">
        <div className={cn("nav-icon", { open: isOpen })}>
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
      {isOpen && (
        <div className={cn("nav-modal", { visible: modalVisible })}>
          {MENU_LINKS.map((link, index) => (
            <MenuLink key={index} {...link} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Menu;
