import { Typography } from "components/typography";
import "./index.scss";

export const BusinessLink = ({ image, title, customClassName = '' }) => {
  return (
    <div className={`business-link ${customClassName}`}>
      <div className="image-container">
        <img
          src={image}
          alt={`Learn more about ${title}`}
          className="business-image"
          loading="lazy"
        />
      </div>
      <div className="business-description">
        <Typography
          tag="strong"
          semibold
          variant="title-xsm"
          customClassname="business-title"
        >
          {title}
        </Typography>
      </div>
    </div>
  );
};
