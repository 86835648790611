import { EXTERNAL_LINKS, INTERNAL_PATHS } from "routes/paths";
import RaiseApp from "assets/images/products-raise-app.png";
import ApiSolutions from "assets/images/products-api-solutions.png";
import GCX from "assets/images/products-gcx.png";
import RaiseIcon from "assets/products-raise-icon.svg";
import PaymentIcon from "assets/products-payment-icon.svg";
import GCXIcon from "assets/products-gcx-icon.svg";

export const CARD_LIST = [
  {
    cover: RaiseApp,
    icon: RaiseIcon,
    title: "Raise App",
    subtitle: "Consumer offering",
    description:
      "Earn Raise Cash instantly at hundreds of your favorite brands, then spend that Raise Cash however you’d like in the app.",
    linkTo: INTERNAL_PATHS.RAISE_APP,
    isNewTab: false,
  },
  {
    cover: ApiSolutions,
    icon: PaymentIcon,
    title: "API Solution",
    subtitle: "Business offering",
    description:
      "Automate and evolve the way your business does loyalty and payments.",
    linkTo: INTERNAL_PATHS.API_SOLUTIONS,
    isNewTab: false,
  },
  {
    cover: GCX,
    icon: GCXIcon,
    title: "GCX",
    subtitle: "Gift Card Exchange",
    description:
      "Buy and sell discounted gift cards on the largest gift card marketplace.",
    linkTo: EXTERNAL_LINKS.GCX_MARKETPLACE,
    isNewTab: true,
  },
];
