import "./index.scss";
import { Typography } from "components/typography";

export const AboutText = () => (
  <div className="about-text">
    <Typography variant="title-lg" semibold customClassname="title">
      We’re on a mission to revolutionize{" "}
      <div className="highlight">loyalty and</div>{" "}
      <div className="highlight">rewards</div> for both brands and consumers.
    </Typography>
    <Typography variant="body-sm" customClassname="subtext">
      As the creators of the largest gift card marketplace, we know a thing or
      two about gift cards. Now, combining our historical industry knowledge
      with the promise of new technologies, we’re building products and
      experiences designed to change the way you shop and interact with your
      favorite brands. <br />
      <br />
      At Raise, we’ve cultivated a group of curious, dedicated individuals to
      create a team we’re proud to work with each day and on each new endeavor.
      Together we’re leveraging the untapped potential of blockchain technology
      to evolve our most-loved products and build new tools we can’t wait for
      the world to see.
    </Typography>
  </div>
);
