import { Container } from "../../../../components/container";
import { Typography } from "../../../../components/typography";
import { BigNumberCard } from "./big-number-card";
import "./styles.scss";

export const BigNumbers = () => {
  return (
    <section id="redefining digital gift cards" className="big-numbers-section">
      <Container>
        <div className="big-numbers-description">
          <Typography
            variant="title-lg"
            tag="h2"
            semibold
            customClassname="dark-grey title"
          >
            We’re redefining digital gift cards
          </Typography>
          <Typography variant="body-lg" tag="p" customClassname="dark-grey">
            Raise is building the future of loyalty and rewards for both
            everyday consumers and global brands. And it’s all powered by gift
            cards.
          </Typography>
        </div>

        <div className="big-numbers-container">
          <BigNumberCard value="6M+" description="customers" />
          <BigNumberCard
            value="1,000+"
            description="brands"
            highlightSize="big"
          />
          <BigNumberCard
            value="$250M"
            description="cash back earned"
            highlightSize="medium"
          />
          <BigNumberCard value="$5B+" description="gift cards sold" />
        </div>
      </Container>
    </section>
  );
};
