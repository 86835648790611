import { Container } from "components/container";
import { Typography } from "components/typography";
import { SliderArea } from "./slider-area";
import "./index.scss";

export const FutureCarousel = () => {
  return (
    <div className="future-carousel-container">
      <Container>
        <Typography
          tag="h2"
          semibold
          variant="title-lg"
          customClassname="future-title"
        >
          Shaping the future of digital engagement
        </Typography>
        <Typography
          tag="p"
          variant="body-lg"
          customClassname="future-description"
        >
          Our suite of products redefines the landscape of gift cards,
          transactions, and brand engagement. From the customizable SmartCard
          and seamless SmartWallet to our interactive SmartMessaging platform
          and the foundational Raise Token, we're not just creating products —
          we're crafting experiences.
        </Typography>
      </Container>

      <div className="slider-area">
        <Container customClassName="slider-container">
          <SliderArea />
        </Container>
      </div>
    </div>
  );
};
