import xIcon from "assets/images/social-links/x.svg";
import instagramIcon from "assets/images/social-links/instagram.svg";
import facebookIcon from "assets/images/social-links/facebook.svg";
import linkedinIcon from "assets/images/social-links/linkedin.svg";

export const SOCIAL_LINKS_LIST = [
  {
    label: "Raise Instagram",
    link: "https://www.instagram.com/raise",
    image: instagramIcon,
  },
  {
    label: "Raise Facebook",
    link: "https://www.facebook.com/RaiseMarketplace",
    image: facebookIcon,
  },
  {
    label: "Raise X",
    link: "https://twitter.com/RaiseApp",
    image: xIcon,
  },
  {
    label: "Raise LinkedIn",
    link: "https://www.linkedin.com/company/raise-com",
    image: linkedinIcon,
  },
];
