import { Typography } from "../../../../../components/typography";
import "./styles.scss";

export const BigNumberCard = ({
  value,
  description,
  highlightSize = 'small'
}) => {

  return (
    <div className="big-number-card">
      <Typography
        tag="p"
        variant="title-xl"
        customClassname={`big-number ${highlightSize}`}
        semibold
      >
        {value}
      </Typography>
      <Typography tag="p" variant="body-lg" customClassname="description">
        {description}
      </Typography>
    </div>
  );
}
