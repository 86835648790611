import { ReactComponent as RaiseFullLogo } from "assets/raise_logo.svg";
import { ReactComponent as RaiseShortLogo } from "assets/raise-short-logo.svg";
import "./index.scss";

export const RaiseLogo = ({
  isShortVersion = false
}) => {

  return (
    <a href="/" className="logo">
      {isShortVersion ? (
        <RaiseShortLogo className="raise-short-logo" />
      ) : (
        <RaiseFullLogo className="raise-logo" />
      )}
    </a>
  )
}
