import AccelLogo from "assets/images/partners/partner-accel.png";
import ListenLogo from "assets/images/partners/partner-listen.png";
import NeaLogo from "assets/images/partners/partner-nea.png";
import PaypalLogo from "assets/images/partners/partner-paypal.png";
import PritzkerLogo from "assets/images/partners/partner-prtizker.png";

export const PARTNER_LIST = [
  {
    name: "Accel",
    logo: AccelLogo,
  },
  {
    name: "PayPal",
    logo: PaypalLogo,
  },
  {
    name: "Listen Ventures",
    logo: ListenLogo,
  },
  {
    name: "New Enterprise Associates",
    logo: NeaLogo,
  },
  {
    name: "The Pritzker Organization",
    logo: PritzkerLogo,
  },
];
