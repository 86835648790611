import { Typography } from "components/typography";
import "./index.scss";

export const HeroSceneDescription = ({
  title,
  description,
  footer,
  customClassName = ''
}) => {

  return (
    <div className={`hero-scene-description ${customClassName}`}>
      <Typography semibold tag="h1" variant="title-xxl">{title}</Typography>
      <div className="scene-description">
        {description}
      </div>
      <div className="scene-footer">{footer}</div>
    </div>
  )
}
