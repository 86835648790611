import { useAppContext } from "context/AppContext";
import { THEME_VIEW } from "constants/themeView";
import { Button } from "components/button";
import "./index.scss";

export const ToggleSwitch = () => {
  const { themeView, toggleThemeView } = useAppContext();
  const getActiveClass = (item) => (item === themeView ? "active" : "");
  return (
    <Button customClassName="toggle-button" onClick={toggleThemeView}>
      <span className={`toggle-item ${getActiveClass(THEME_VIEW.CONSUMER)}`}>
        Consumer
      </span>
      <span className={`toggle-item ${getActiveClass(THEME_VIEW.BUSINESS)}`}>
        Business
      </span>
      <span className={`highlight-item ${themeView}`} />
    </Button>
  );
};
