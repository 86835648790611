import EarnLinks from "components/earn-links";
import s from "components/earn-links/index.module.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import useMedia from "react-use/lib/useMedia";

export const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMedia("(max-width: 767px)");

  return (
    <div className={s.container}>
      <EarnLinks />
      <div className={s.content}>
        <div className={s.contentHeader}>
          Raise{isMobile ? <br /> : " - "}
          Terms Of Use
        </div>
        <div className={s.subtext}>Last updated: October 30, 2023</div>
        <div className={s.mainContent}>
          <div className={s.mainContentTopic}>1. Introduction</div>
          <p className={s.pointParagraph}>
            Welcome to the Raise mobile application (the &ldquo;App&rdquo;), a
            mobile payments app run by Raise Marketplace, LLC.
            (&ldquo;we&rdquo;, &ldquo;our&rdquo;, &ldquo;us&rdquo;,
            &ldquo;Raise&rdquo;). The App allows you to purchase and redeem
            App-exclusive gift cards, gift codes, and other similar pre-paid
            closed-loop products issued by third-party Merchants in electronic
            form (collectively, &ldquo;Gift Cards&rdquo;) and use these Gift
            Cards to purchase goods or services from App-available merchants
            (&ldquo;Merchants&rdquo;) in-person or online. The App allows you to
            purchase Gift Cards using your preferred payment method, or via
            Raise Cash, you maintain on your App account when you purchase our
            proprietary and unique closed-loop Raise payment mechanism which is
            usable solely for future Gift Card purchases from Raise as further
            described below.
          </p>

          <p>
            By clicking &quot;I agree&quot; on the App or otherwise using the
            App, you agree and acknowledge that you have fully read and
            consented to be bound by these Terms of Use (the &quot;Terms of
            Use&quot;), the App&apos;s{" "}
            <Link to="/earn/privacy-policy" className={s.link}>
              Privacy Policy
            </Link>
            , and any additional or different terms and/or conditions that may
            apply to your use of the App or to a service or product offered via
            the App (in each such instance and collectively, &quot;Additional
            Terms&quot;). If you do not agree to these Terms of Use, you may not
            access or use the App or any of the services provided on the App. It
            is your responsibility to read the Terms of Use carefully and
            periodically review the Terms of Use as posted on the Raise Website
            and/or app. Your continued use of the Services shall signify your
            acceptance to be bound by the then-current Terms.
          </p>

          <p>
            SECTION 16 OF THESE TERMS OF USE CONTAINS AN ARBITRATION CLAUSE AND
            A CLASS ACTION AND TRIAL BY JURY WAIVER. IT AFFECTS HOW DISPUTES
            WITH Raise ARE RESOLVED. BY ACCEPTING THESE TERMS OF USE, YOU AGREE
            TO BE BOUND BY THE ARBITRATION PROVISION AND THE CLASS ACTION AND
            TRIAL BY JURY WAIVER.
          </p>

          <p>
            SECTION 4 OF THESE TERMS OF USE CONTAINS INFORMATION ON YOUR GIFT
            CARDS PURCHASES VIA THE APP INCLUDING THE APP&apos;S NONREFUNDABLE
            PURCHASE POLICY. BY ACCEPTING THESE TERMS OF USE, YOU AGREE TO BE
            BOUND BY THE NONREFUNDABLE PURCHASE POLICY.
          </p>

          <div className={s.mainContentTopic}>
            2. Modification of Terms of Use
          </div>
          <p className={s.pointParagraph}>
            We reserve the right at all times to update, modify or discontinue
            any part of these Terms of Use as we deem necessary or desirable, in
            our sole discretion. Changes to the Terms of Use, we will notify you
            by posting notice of the changes to the Terms of Use on Raise. Any
            changes to these Terms of Use will be effective upon the earlier
            dispatch of an e-mail or other notice to you informing you of
            changes to these Terms of Use or our posting of notice of the
            changes on Raise. We suggest that you revisit our Terms of Use page
            on Raise from time to time to ensure that you stay informed of any
            changes to our Terms of Use. Your use of Raise after we update the
            Terms of Use and post it on Raise will constitute your acceptance of
            the modified Terms of Use.
          </p>

          <div className={s.mainContentTopic}>
            3. Registering, Eligibility and Member Accounts, and Permissions
          </div>
          <p className={s.pointParagraph}>
            There are no fees to join Raise, use many of the features on Raise
            or to act as a buyer of Gift Cards on Raise. You can join Raise by
            registering and setting up a Member Account on Raise via our mobile
            applications (&quot; Member Account &quot;). By registering a Member
            Account, you represent that you are at least the age of majority in
            your jurisdiction or, if you are under the age of majority in your
            jurisdiction. A parent or legal guardian who registers a Member
            Account for a person under the age of majority represents and
            warrants that: (i) you are a custodial parent or duly appointed
            legal guardian of the minor for whom you have established a Member
            Account; (ii) all information you have provided in the Member
            Account establishment process is true and correct; (iii) the minor
            you have registered is not under the age of 13; (iv) the minor you
            have registered has your authority to use the payment method that
            you maintain on the Member Account; (v) you agree to take full legal
            and financial responsibility for all activity conducted in the
            Member Account; and (vi) THE MINOR AND YOU ARE BOTH EQUALLY SUBJECT
            TO SECTION 16 OF THESE TERMS OF USE, WHICH INCLUDE A PROVISION ON
            BINDING ARBITRATION AND A CLASS ACTION AND TRIAL BY JURY WAIVER.
          </p>

          <p>
            We permit limited access to Raise and services available on Raise to
            guests and visitors who have not registered a Member Account. In
            order to access all of the benefits of Raise, including the ability
            to buy Gift Cards, you must set up a Member Account. You may only
            set up one Member Account. Furthermore, you must fully comply with
            the following requirements: (i) provide complete, current and
            accurate information at time of registration, (ii) update all
            relevant Account information to keep it current and accurate, and
            (iii) maintain the security and confidentiality of your Member
            Account, so as to minimize the risk of unauthorized access of your
            Member Account. Finally, you agree to inform Raise immediately if
            you suspect that your Member Account at Raise has been hacked or
            subject to any unauthorized access or use.You may use the same phone
            number associated with your Raise account on the new Raise Platform.
            However, any cards or Raise Cash you&apos;ve earned on Raise will
            not be transferable to the new Raise app. You agree that Raise may
            verify your information through whatever lawful means it deems
            reasonable and appropriate, to request additional information to
            confirm your identity and to deny you access to Raise if it
            determines any information provided is suspect, not accurate or
            cannot be confirmed.
          </p>

          <p>
            You are solely responsible for creating a strong password and
            maintaining adequate security and control of any and all IDs,
            passwords, hints, personal identification numbers (PINs), API keys
            or any other codes that you use to access our Services. Any loss or
            compromise of the foregoing information and/or your personal
            information may result in unauthorized access to your account by
            third parties and the loss or theft of any funds associated with
            your account, including your linked payment methods. You are solely
            responsible for keeping your email address, telephone number and
            other contact details up to date in your account profile in order to
            receive any notices or alerts that we may send you. You should never
            allow remote access or share your computer screen with someone else
            when you are logged on to your account. We assume no responsibility
            for any loss that you may sustain due to compromise of account login
            credentials due to no fault of Raise and/or your failure to follow
            or act on any notices or alerts that we may send to you.
          </p>

          <p>
            We reserve the right to interrupt your service, your access to your
            Member Account, and/or your access to Raise or any services or Gift
            Cards offered on Raise, with or without prior notice, for any
            reason, at our sole and absolute discretion. In the event your
            Member Account or access to it is interrupted or terminated for any
            reason, whether intentionally or unintentionally, we will not be
            liable for any losses due in whole or in part by such interruption
            or termination of your Member Account or your access to Raise.
          </p>

          <p>
            By using the App, you agree that Raise may access your device in
            order to allow for or optimize your use of the App. For instance,
            our App may access, use and read settings, data and/or content on
            your device, and/or add content to your device, detect the
            device&apos;s location, or change settings of your device. You
            consent to these activities by installing the App, using the App, or
            setting certain permissions or settings allowing such activities.
            Your device settings may enable you to disable, change or limit some
            of these activities, and you can disable all of them associated with
            the App by uninstalling the App
          </p>

          <div className={s.mainContentTopic}>
            4. Promotional Raise Cash Program
          </div>
          <p className={s.pointParagraph}>
            Raise Cash is a promotional reward program offered by Raise to its
            Members. Raise may offer a variety of Raish Cash rewards
            (collectively, &quot;Raise Cash&quot;) pursuant to various programs
            (&quot;Raise Cash Programs&quot;) to users with Member Accounts. The
            Raise Cash Terms available at{" "}
            <Link to="/earn/raisecashterms" className={s.link}>
              Raise Cash Terms
            </Link>{" "}
            govern your participation in any and all Raise Cash Programs. By
            participating in any of the Raise Cash Programs, including earning
            and redeeming any Raise Cash offered under any of the Programs, you
            agree to the Raise Cash Terms, which terms are incorporated herein
            by reference. Users are strictly prohibited from engaging in any
            form of selling or trading of Raise Cash, whether with other users
            or any third parties. Any attempt to sell or trade Raise Cash will
            be considered a breach of these terms of use and may result in
            immediate account suspension or termination.
          </p>

          <div className={s.mainContentTopic}>
            5. Use of Raise to Purchase, Redeem and Manage Your Gift Cards
          </div>
          <p>(a) General Merchant Gift Card Terms & Conditions</p>

          <p>
            Managing and Using Your Gift Cards on Raise, Gift Card Terms &
            Conditions and Your Responsibility Related to Gift Cards Used or
            Managed on Raise. Raise allows you to more effectively buy, redeem
            and manage your Gift Cards. Raise is not responsible for any loss or
            damage resulting from the loss of any such Gift Card or the use of
            your Raise user account without your permission. PURCHASES OF GIFT
            CARDS ARE FINAL AND NON-REFUNDABLE, UNLESS OTHERWISE INDICATED IN
            ADDITIONAL TERMS. We do not guarantee any associated savings or
            earnings you may receive in conjunction with Gift Cards. Raise is
            not related to any of the Merchants whose Gift Cards appear on the
            platform.
          </p>

          <p>
            For certain Merchant Gift Cards on Raise, you may be able to
            transfer Gift Card balances between Gift Cards of the same Merchant.
            Raise is never responsible for the loss of any Gift Card balance
            transferred from a Gift Card not purchased on Raise, whether in the
            event of a Gift Card being closed by a Merchant or otherwise.
          </p>

          <p>
            Gift Cards may be subject to various other terms and conditions
            related to a particular Merchant or Gift Card, including, without
            limitation, use limitations as to use of the Gift Card, combination
            with other offers, redemption and voucher use rules, and whether a
            Gift Card can be used online, in store or both. These terms and
            conditions are generally presented with the Gift Card on Raise and
            you agree specifically to have read and accepted these particular
            terms and conditions when you agree to purchase the Gift Card in
            question on Raise.
          </p>

          <p>
            Raise is not a party to any transaction that you may enter into with
            the Merchant as a result of purchasing a Merchant&apos;s Gift Card
            on Raise. Raise is not responsible for the quality of goods or
            services you acquire from a Merchant or otherwise procure using a
            Gift Card acquired on Raise. If a Merchant were to go out of
            business or go into bankruptcy, or undergo any action that results
            in your Gift Card to be unredeemable prior to your use of all the
            value on their Gift Card, you understand that your sole and
            exclusive recourse with respect to the unused value of the Gift Card
            for that particular Merchant is with the Merchant in question (and
            not Raise) and, as an unsecured-creditor, you may not be able to
            recoup the value of any unused Gift Card for such a Merchant. Raise
            is noy liable for any loss of value or other result arising from
            recourse you seek from Merchants.
          </p>

          <p>
            (b) Raise Purchase Transaction using an instant-issue Gift Card
            Purchase
          </p>

          <p>
            You may use your Raise Cash to purchase instant-issue Gift Cards
            when making a purchase from a participating Merchant. No Gift Card
            purchase transaction is complete until Raise verifies (i) the value
            of the instant-issue Gift Card associated with the purchase, (ii)
            the value of your Raise Cash, and (iii) your identity as an App user
            and the purchaser of the Gift Card. Once the instant-issue Gift Card
            is purchased, you may immediately present it to the Merchant to pay
            for your purchase. While most transactions will be completed
            immediately, Raise reserves the right, as a part of the verification
            process, to require you to provide additional identification
            information, including, without limitation, a copy of your valid
            driver&apos;s license (or other form of identification) prior to or
            after purchasing a Gift Card. You may also be asked to go through
            additional security procedures and share additional information to
            confirm your identity. Raise reserves the right to reject or cancel
            any transaction(s) that we believe (in our sole discretion) may: (i)
            be fraudulent, (ii) have come from an unauthorized or illegal
            source, (iii) be related to any illegal activity or (iv) otherwise
            pose a financial risk to us, App users, or Merchants.
          </p>

          <p>(c) Payment Options & Fees</p>

          <p>
            In order to purchase your instant-issue Gift Cards, you may use
            various forms of payment, including credit cards, debit cards,
            certain cryptocurrencies (Zero Hash), credit provided by us in your
            Member Account, Apple Pay, PayPal or other third-party payment
            services. By purchasing or increasing the value of your Raise Cash
            or purchasing instant-issue Gift Cards, you warrant that you are
            authorized to use the designated debit or credit card or other
            payment method and you authorize us to charge your designated credit
            or debit card or other payment method for adding additional value to
            your Raise Cash, or purchasing instant-issue Gift Cards. When you
            provide your payment information, you authorize us (or our payment
            processor vendors) to process and store your payment and related
            information. In the event that the designated credit or debit card
            or other payment method cannot be verified, is invalid or is
            otherwise not acceptable, we may (i) suspend or cancel your order
            automatically, (ii) prohibit you from purchasing or reloading the
            Raise Balance or purchasing instant-issue Gift Cards until a valid,
            verifiable credit/debit card or other payment method is on file with
            your Member Account and/or (iii) suspend or terminate your Member
            Account. Payment processing fees may apply even if the designated
            payment method has expired or changed by the time we submit the
            charges to your card. We are not responsible for any payment method,
            debit card or overdraft fees. If you use a crypto currency and it is
            converted to U.S. dollars and we cancel or reverse your transaction,
            we are not responsible for conversion of your U.S. dollars to a
            particular cryptocurrency. You agree to fully cooperate with Raise
            in an effort to resolve any problems we encounter in order to
            process your requested order on the platform.
          </p>

          <p>(d) Vendor Terms and Conditions</p>

          <p>
            Certain payment processing services for Raise are provided by our
            vendors such as Braintree, and are subject to the{" "}
            <a
              href="https://www.braintreepayments.com/legal/payment-services-agreement"
              target="_blank"
              rel="noopener noreferrer"
              className={s.link}
            >
              vendor&apos;s terms and conditions
            </a>
            . By agreeing to these Terms of Use or making a purchase
            transaction, you further agree to be bound by the Braintree Payment
            Services Agreement, as the same may be modified by Braintree from
            time to time. As a condition of us enabling payment processing
            services through Braintree, you agree to provide us accurate and
            complete information about you, and if applicable, your business.
            You further authorize us to share all such information, including
            all transaction information, related to your use of the payment
            processing services provided by Braintree.
          </p>

          <p>(e) Improper Refunds</p>

          <p>
            In the event Raise determines, in its sole discretion, that you have
            mistakenly, improperly or fraudulent sought and received a refund
            for the Raise Cash purchased on the App, for a Gift Card purchased
            with your Raise Cash, or for an instant-issue Gift Card purchased on
            the App, you authorize Raise to charge your payment method on file
            with the App or debit your Member Account (including any of your
            Raise Cash) the full amount of any such improper refund(s) requested
            by you. Furthermore, in this circumstance, Raise reserves the right
            to suspend or terminate your Raise Member Account and/or your Raise
            Member Account.
          </p>

          <div className={s.mainContentTopic}>
            6. Authorized Use & Termination of Access
          </div>
          <p className={s.pointParagraph}>
            You can use Raise only for its intended purposes and solely in
            accordance with these Terms of Use. Subject to your full compliance
            with these Terms of Use, Raise grants you a limited, nonexclusive,
            non-sublicensable license to access and use Raise solely for the
            purpose of purchasing Gift Cards or receiving other available
            services and features on Raise. This limited use and access license
            is revocable at any time by us.
          </p>

          <p>By using the Raise App you agree to the following rules:</p>

          <ul className={s.bulletList}>
            <li className={s.bullet}>
              You must purchase Gift Cards for a minimum value of $5 USD;
            </li>
            <li className={s.bullet}>
              Your purchase amount must be greater than or equal to $25 when
              redeeming promotional credit;
            </li>
            <li className={s.bullet}>
              If you have more than $1,000 USD available in Raise Cash at any
              point in time, you must redeem Raise Cash to continue earning
              Raise Cash;
            </li>
            <li className={s.bullet}>
              You must always maintain a valid and up to date payment method
              (with available funds) on file with your Member Account;
            </li>
            <li className={s.bullet}>
              You may only use the App for your own personal use and not as a
              representative for a group of people, business, or other
              organization;
            </li>
            <li className={s.bullet}>
              Your earnings (Raise Cash) will only be able to be applied to
              future purchases in the app. You will not be able to withdraw your
              earnings.
            </li>
            <li className={s.bullet}>
              Your Raise account will remain separate from your Raise app
              account, so cards from your Raise account will not be accessible
              on the new Raise Platform.
            </li>
          </ul>

          <p>
            You also agree not to facilitate, participate in, or do any of the
            following (each a &quot;Prohibited Activity&quot;):
          </p>

          <ul className={s.bulletList}>
            <li className={s.bullet}>Create more than one Member Account;</li>
            <li className={s.bullet}>
              Upload or otherwise add gift cards or credits not purchased via
              the App, including by circumventing or taking advantage of errors
              in the App&apos;s code to do so;
            </li>
            <li className={s.bullet}>
              Purchase more than $10,000 USD worth of Raise Cash or of face
              value Gift Cards per day;
            </li>
            <li className={s.bullet}>
              Purchase a single Gift Card worth more than $2,000 USD;
            </li>
            <li className={s.bullet}>
              Have more than $1,000 USD available in Raise Cash at any point in
              time;
            </li>
            <li className={s.bullet}>
              Allow your Raise Cash to become negative, unless otherwise
              permitted by these Terms of Use;
            </li>
            <li className={s.bullet}>
              Users shall not be entitled to earn Raise Cash on any purchase
              amount paid for using Raise Cash;
            </li>
            <li className={s.bullet}>
              Users may earn Raise Cash on only purchase paid with payment
              method; will not earn Rash Cash when purchasing with Raise Cash;
            </li>
            <li className={s.bullet}>
              Cannot use Raise Cash earned on Raise account for the Raise
              Platform;
            </li>
            <li className={s.bullet}>
              Cannot use Raise Cash earned on Raise Platform for Raise;
            </li>
            <li className={s.bullet}>
              Authorize another person to use your Member Account, unless
              otherwise permitted by Raise in writing;
            </li>
            <li className={s.bullet}>
              Modify, alter or otherwise make any derivative uses of the App
              content, trademarks, intellectual property, technology platform or
              its underlying software, or any portion thereof;
            </li>
            <li className={s.bullet}>
              Copy, reverse engineer, decompile, disassemble or attempt to
              discover the source code of the App technology platform or its
              underlying software;
            </li>
            <li className={s.bullet}>
              Use any automated or non-automated means of data gathering, data
              mining or extraction methods on or in connection with the App,
              including any use of &quot;robots&quot;, &quot;scrapers&quot;,
              &quot;spiders&quot;, &quot;readers&quot; or similar devices;
            </li>
            <li className={s.bullet}>
              Infringe upon or violate the rights of Raise and its service
              providers, App users, third parties or Merchants;
            </li>
            <li className={s.bullet}>
              Download, copy or imitate any portion of the App technology
              platform, its underlying software and content, or its user
              interface, except as expressly permitted by Raise;
            </li>
            <li className={s.bullet}>
              Use the App in any way that could damage, disable, overburden,
              place excessive demand on or impair the App any services we offer
              via the App;
            </li>
            <li className={s.bullet}>
              Attempt to gain unauthorized access to any personal information or
              Member Account or the App, including but not limited to any
              computer systems and/or network systems, through any means,
              including via a proxy server, or use any unauthorized access
              whether or not intentionally acquired;
            </li>
            <li className={s.bullet}>
              Aggregate or scrape any content, data or other information from
              the App (e.g., using links, scraping technology or other technical
              means) to be aggregated or shown with material from other online
              services or on a secondary online service without our express
              written permission;
            </li>
            <li className={s.bullet}>
              Hyperlink or direct individuals to the App without our written
              consent;
            </li>
            <li className={s.bullet}>
              Interfere with the App&apos;s operations, attempt to access or
              steal any data and information on or related to the App;
            </li>
            <li className={s.bullet}>
              Seek to commit any fraudulent or illegal activity, through any
              means or corrupt practices, including but not limited to any of
              the following: hacking, password mining, deceptive impersonation
              of another person, misrepresentation of your affiliation with a
              person or entity, hiding or attempting to hide your true identity
              or location (including via proxy server or otherwise) or providing
              false, inaccurate or altered documentation, information or
              identification;
            </li>
            <li className={s.bullet}>
              Circumvent or seek to circumvent any security measures or other
              features;
            </li>
            <li className={s.bullet}>
              Make any unsolicited offers, advertisements, proposals, or send
              junk mail, spam or chain letters to other App users or any other
              similar type of solicitation;
            </li>
            <li className={s.bullet}>
              Upload images, content or other material that is illegally
              obtained, in violation of a third party&apos;s intellectual
              property rights, or offensive, libelous, defamatory, harassing,
              threatening, inappropriate, deceptive or misleading in any way;
            </li>
            <li className={s.bullet}>
              Send or receive what we reasonably believe to be potentially
              fraudulent funds or otherwise use the App in a manner that we
              believe is intended to facilitate a criminal or fraudulent
              activity or enterprise;
            </li>
            <li className={s.bullet}>
              Seek to access or use the App from a country other than the U.S.;
            </li>
            <li className={s.bullet}>
              Use the App in a manner that results in or may result in
              complaints, chargebacks, refunds, invalid Gift Cards, fees,
              penalties or other liability to Raise, Raise partners or App
              users;
            </li>
            <li className={s.bullet}>
              Make any fraudulent, improper or false refund request or claim;
            </li>
            <li className={s.bullet}>
              Engage in, or be involved in, any criminal and/or fraudulent
              activities, including not limited to the use of any fraudulent or
              unauthorized payment method to acquire Gift Cards on the App and
              the use of Gift Cards to perpetrate or facilitate any other
              criminal activity;
            </li>
            <li className={s.bullet}>
              Violate any terms and conditions set forth in the, Terms of Use,
              Raise Cash Terms, or any Additional Terms; and
            </li>
            <li className={s.bullet}>
              Use the App other than for its intended purposes (including for
              any malicious, threatening, unethical, harassing, fraudulent or
              illegal activity) or otherwise in violation of these Terms of Use.
            </li>
          </ul>

          <p>
            Your Member Account with Raise and any access to the products,
            services, your Raise Cash, and/or any portion of Raise, is and shall
            remain at the sole and absolute discretion of Raise. Therefore, we
            reserve the right to temporarily or permanently terminate, suspend,
            or refuse to permit your access to any portion of Raise (including
            any of our mobile applications and your Member Account) and any of
            the services provided on Raise for any reason, all at our sole and
            absolute discretion, with or without notice and without liability to
            you of any kind. You further agree that you will remain bound to the
            terms of these Terms of Use at all times during any suspension and
            after termination of your Member Account or access to Raise, with
            respect to any purchase transactions, occurrences or acts or
            omissions on your part prior to or during the suspension, or prior
            to termination. We reserve the right to terminate or change any of
            the services available on Raise including any services or programs
            related to Promotional Raish Cash. Any Cash Back (if applicable)
            will be converted to Raise Cash and will not be able to be withdrawn
            via ACH or bank account.
          </p>

          <p>
            If you are using the Services on behalf of a legal entity such as a
            corporate entity, you further represent and warrant that: (i) the
            legal entity is duly organized and validly existing under the
            applicable laws of the jurisdiction of its organization; and (ii)
            you are duly authorized by such legal entity to act on its behalf. A
            corporate-verified account is specific to that legal entity and can
            only be used by the person who registered it. Corporate accounts are
            not allowed to be shared with or used by other individuals or
            entities. Corporate accounts that are verified are allowed the
            following limited exemptions:
          </p>

          <p>
            An approved corporate account may have several active user accounts
            at any time, provided they are all company-verified and operated by
            designated employees of the company that have been previously
            disclosed and approved by Raise in its sole and absolute discretion;
          </p>

          <p>
            Corporate accounts may only have one active offer for a specific
            transaction at any one time and are not allowed to have multiple
            offers for such specific transaction from their other corporate
            accounts.
          </p>

          <div className={s.mainContentTopic}>7. Prohibited Activity</div>
          <p className={s.pointParagraph}>
            In connection with using or accessing Raise (including your Member
            Account), whether via the website, our mobile applications or
            otherwise, you agree not to do, and not to facilitate any third
            party doing, any of the following (each a &quot;Prohibited
            Activity&quot; ):
          </p>

          <p>
            (a) Modify, alter or otherwise make any derivative uses of the Raise
            content, trademarks, technology platform or its underlying software,
            or any portion thereof;
          </p>

          <p>
            (b) Copy, reverse engineer, decompile, disassemble or attempt to
            discover the source code of the Raise technology platform or its
            underlying software;
          </p>

          <p>
            (c) Use any automated or non-automated means of data gathering, data
            mining or extraction methods on or in connection with Raise,
            including any use of &quot;robots&quot;, &quot;scrapers&quot;,
            &quot;spiders&quot;, &quot;readers&quot; or similar devices;
          </p>

          <p>
            (d) Infringe upon or violate the rights of Raise, its users, third
            parties or Merchants;
          </p>

          <p>
            (e) Download, copy or imitate any portion of the Raise technology
            platform, its underlying software and content, or its user
            interface, except as expressly permitted by Raise;
          </p>

          <p>
            (f) Use Raise in any way that could damage, disable, overburden,
            place excessive demand on or impair our Site, mobile applications or
            any services we offer via Raise;
          </p>

          <p>
            (g) Attempt to gain unauthorized access to (i) any Member&apos;s
            personal information, personal profile, or Member Account or (ii)
            Raise and its mobile applications, including but not limited to any
            Raise accounts, computer systems and/or network systems, through any
            means, including via a proxy server, or use any unauthorized access
            whether or not intentionally acquired;
          </p>

          <p>
            (h) Aggregate or scrape any content, data or other information from
            the Site (whether using links, scraping technology or other
            technical means) to be aggregated or shown with material from other
            sites or on a secondary site without our express written permission;
          </p>

          <p>
            (i) Hyperlink to the Site or deep-link to any portion of our Site
            without our written consent;
          </p>

          <p>
            (j) Interfere with our Site&apos;s or our mobile applications&apos;
            operations, attempt to access or steal any data and information on
            or related to the Site;
          </p>

          <p>
            (k) Seek to transact any fraudulent or illegal activity, through any
            means or corrupt practices, including but not limited to any of the
            following: hacking, password mining, deceptive impersonation of
            another person, misrepresentation of your affiliation with a person
            or entity, hiding or attempting to hide your true identity or
            location (including via proxy server or otherwise) or providing
            false, inaccurate or altered documentation, information or
            identification;
          </p>

          <p>
            (l) Circumvent or seek to circumvent any security measures or other
            features meant to protect the security of the Site and Raise
            Members&apos; security;
          </p>

          <p>
            (m) Make any unsolicited offers, advertisements, proposals, or send
            junk mail, spam or chain letters to other Raise users or any other
            similar type of solicitation not approved of by Raise in writing;
          </p>

          <p>
            (n) Upload to Raise images, content or other material that is
            illegally obtained, in violation of a third party&apos;s
            intellectual property rights, or offensive, libelous, defamatory,
            harassing, threatening, inappropriate, deceptive or misleading in
            any way;
          </p>

          <p>
            (o) Send or receive what we reasonably believe to be potentially
            fraudulent funds or otherwise use Raise in a manner that we believe
            is intended to facilitate a criminal or fraudulent activity or
            enterprise;
          </p>

          <p>
            (p) Seek to access or use Raise from a country other than the U.S.;
          </p>

          <p>
            (q) Use Raise in a manner that results in or may result in
            complaints, chargebacks, customer refunds, invalid Gift Cards, fees,
            penalties or other liability to Raise, its other members or
            partners;
          </p>

          <p>
            (r) Make any fraudulent, improper or false refund request or claim;
          </p>

          <p>
            (s) Engage in, or be involved in, any criminal and/or fraudulent
            activities, including not limited to, the following with regard to
            any Gift Cards bought on Raise: (i) the use of any fraudulent or
            unauthorized payment method to acquire Gift Cards on Raise or (ii)
            the use of Gift Cards to perpetrate or facilitate any other criminal
            activity; and
          </p>

          <p>
            (t) Violate any terms and conditions set forth in the Raise Cash
            Terms; and
          </p>

          <p>
            (u) Use Raise other than for its intended purposes (including for
            any malicious, threatening, unethical, harassing, fraudulent or
            illegal activity) or otherwise in violation of these Terms of Use.
          </p>

          <div className={s.mainContentTopic}>
            8. Your Liability - Actions We May Take and Fees We May Assess
          </div>
          <p>
            (a) General. You are responsible for all chargebacks, fees, fines,
            penalties and other liabilities incurred by us, any Member, a
            Merchant or any third party caused by or arising out of your breach
            of these Terms of Use, any Prohibited Activity and/or your use of
            Raise.
          </p>

          <p>
            (b) Actions We May Take - Related to Prohibited Activity or Any
            Breach of these Terms of Use. If Raise, in its sole discretion,
            believes that you have breached these Terms of Use or engaged in any
            Prohibited Activity, we take various actions to protect Raise, our
            Members, Merchants or others from claims, refunds, chargebacks,
            fees, fines penalties or any other liabilities or exposure. The
            actions we may take include, without limitation, the following:
          </p>

          <ul className={s.bulletList}>
            <li className={s.bullet}>
              We may close, suspend or limit your access to your Member Account
              or Raise (including limiting your access to your Raise Cash,
              and/or your Gift Cards, your ability to purchase Gift Cards from
              us);
            </li>
            <li className={s.bullet}>
              We may refuse to allow you to be a Member or otherwise access
              Raise in the future;
            </li>
            <li className={s.bullet}>
              We may hold your Raise Cash if reasonably needed to protect
              against the risk of liability in connection with member refunds or
              as otherwise provided in these Term of Use;
            </li>
            <li className={s.bullet}>
              We may terminate any transactions on Raise and withhold any
              payments associated with such transactions;
            </li>
            <li className={s.bullet}>
              We may contact your bank and/or credit card issuer, Merchants, law
              enforcement and any other third parties impacted by your
              activities;
            </li>
            <li className={s.bullet}>
              We may investigate any Prohibited Activity or breach of these
              Terms of Use or the Raise Cash Terms and may notify and cooperate
              with appropriate law enforcement agencies;
            </li>
            <li className={s.bullet}>
              We can and may share any information related to your Member
              Account, transaction history, payment information and other
              information related to your usage of Raise with law enforcement or
              relevant Merchants, to the extent requested in connection with any
              investigation into any potentially criminal, fraudulent or
              Prohibited Activity;
            </li>
            <li className={s.bullet}>
              We may use information you provide us or related to your activity
              on Raise to investigate any potentially fraudulent or criminal
              activity or any Prohibited Activity, including to ensure you have
              not made improper or fraudulent refund requests under any Raise
              refund policy or member guarantee;
            </li>
            <li className={s.bullet}>
              We may take legal action against you; and
            </li>
            <li className={s.bullet}>
              We may, in our sole discretion, terminate all access to Raise,
              your Member Account and our services for any reason and at any
              time.
            </li>
          </ul>

          <p>
            (c) Actions We Take - Holds and Reserves on Funds and your Member
            Account.
          </p>

          <p>
            i. Legal Process & Court Orders. We may, in our sole discretion,
            take various actions, including placing a hold, reserve or other
            limitations on your Member Account or the funds or credits in your
            Member Account in the event we receive notice of a court order or
            other legal process that restricts the use of or access to your
            funds or requires their holding, release, or seizure. We will
            provide notice of such hold, reserve or limitation, unless the court
            or law enforcement otherwise directs us not to provide you notice,
            in which case the court order or other process will supersede any
            notice obligation in these Terms of Use. In addition, in the event
            we receive a notice of garnishment, lien or levy on your Member
            Account directing us to retain funds or directing us to pay funds
            from your Member Account to a court, government body, agency or
            third party, we may limit your Member Account, hold relevant funds
            and ultimately disburse funds as necessary.
          </p>

          <p>
            ii. Risk-Based and Verification Reasons. We may, in our sole
            discretion, place holds on releasing of funds to you or reserves on
            funds in your Member Account (including any Raise Cash), under the
            following circumstances: (A) as we investigate any Gift Card refund
            requests, or any potentially illegal or fraudulent activity or any
            Prohibited Activity, (B) if we believe there may be a high level of
            risk associated with you, your Member Account or your transactions
            on Raise, and (C) until we have been able to complete certain
            verification procedures relate you and the processing of your
            Transactions (including third party processor verification
            requirements). Our determination that you are a higher risk account
            may be based on a variety of factors, including your activity on
            Raise and information we may receive from third parties or
            otherwise. If we place a reserve or hold on funds in your Member
            Account (or debit your Raise Cash in your Member Account), you will
            be informed of such reserve, hold or action. If your Member Account
            is subject to a reserve, we will provide you notice of this reserve,
            specifying the terms of the reserve or the policy under which such
            reserve is being placed.
          </p>

          <p>
            (d) Our Right to Reverse Refunds. In the event Raise determines, in
            its sole discretion, that you have mistakenly, improperly or
            fraudulent sought and received a refund for a deposit to your Raise
            Cash or for a Gift Card purchased on Raise under our then current
            policy, you authorize Raise to charge your payment method on file
            with Raise or debit your Member Account (including any of your Raise
            Cash) the full amount of any such improper refund(s) requested by
            you. Furthermore, in this circumstance, Raise reserves the right
            suspend or terminate your Member Account on Raise.
          </p>

          <p>
            (e) Dispute of any Raise Recourse. In the event you believe in good
            faith that there may have been an error in our assessment of any
            fees, charges or other permitted recourse to your Member Account
            associated with a Gift Card you purchased on Raise (including the
            recourse in this Section 6), you may contact Raise Support (which
            information is available on our Site). You must provide us with all
            relevant information to support the reason you believe the recourse
            we took with regard to your Member Account or a particular Gift Card
            you bought on Raise was not warranted and we will then, at our
            discretion, engage in further investigation of the particular matter
            (to the extent possible) to determine if there was any error in our
            assessment of any fees or charges to your account or any other
            similar permitted recourse hereunder. You hereby agree to fully
            cooperate with Raise in any such further investigation and to only
            provide us with full and truthful information. Raise will
            communicate its determination (after any such investigation) in
            accordance with its then existing policies and procedures. The
            timing and terms of any additional investigative process will be
            entirely dependent upon our then current policies and procedures
            related to such matters, which may change from time-to-time. You
            further agree that Raise&apos;s determination after such additional
            investigation (to the extent possible) is final.
          </p>

          <div className={s.mainContentTopic}>
            9. Negative Accounts & Raise Recourse
          </div>
          <p className={s.pointParagraph}>
            If your Member Account becomes negative for any reason, we may close
            or suspend your Member Account for an outstanding negative balance,
            and you may be responsible for collection and related legal costs we
            incur. We may also first deduct any amounts you owe us from any
            amount we owe you under your Member Account or any related Member
            Accounts. In the event your Member Account is suspended or
            terminated for any reason, any amount due on your account will be
            immediately due and payable. Closing of Member Accounts or opening
            and maintaining more than one Member Account does not alter your
            total liability to us, and we may combine amounts due to us from
            multiple Member Accounts. We may report information about your
            Member Account to credit bureaus, law enforcement or Merchants, so
            defaults on your account may be reflected in your credit report.
          </p>

          <div className={s.mainContentTopic}>
            10. Intellectual Property & Trademarks
          </div>
          <p className={s.pointParagraph}>
            Unless otherwise indicated by us, the Raise site, mobile
            applications and all content and other materials therein, including,
            without limitation, the Raise trademarks, logos and all other Raise
            designs, text, graphics, pictures, information, data, software,
            sound files, other files made available on Raise and the selection
            and arrangement thereof, and any documentation provided to you by or
            on behalf of Raise (but excluding any and all trademarks, service
            marks or other intellectual property of Merchants found on Raise)
            are the exclusive property of Raise. &quot;Raise&quot; and the Raise
            logo and any related slogans are Raise&apos;s trademarks in the
            United States and may not be copied or used without the prior
            written permission of Raise. Unless explicitly stated by us, nothing
            in these Terms of Use shall be construed as conferring any ownership
            in or license to any intellectual property rights, whether by
            estoppel, implication or otherwise.
          </p>

          <p>
            All trademarks not owned by Raise that appear on Raise are the
            property of their respective owners. Reference to any products,
            services, merchants or retailers available on Raise does not
            constitute or imply any endorsement, sponsorship or recommendation
            by Raise. Furthermore, Raise is not the issuer of any of the Gift
            Cards available for sale on Raise and is not related to any of the
            Merchants whose trademarks and Gift Cards appear on Raise for sale.
          </p>

          <p>
            By posting or uploading any information, content or material to
            Raise&apos;s Site, you hereby agree to the following:
          </p>

          <p>
            You hereby grant Raise an irrevocable, perpetual, non-exclusive,
            fully paid, worldwide license to use, copy, perform, display, and
            distribute said information, content or material and to grant and
            authorize sub-licenses of the foregoing.
          </p>

          <p>
            You hereby grant Raise all rights necessary to prohibit any
            subsequent aggregation, display, copying, duplication, reproduction
            or exploitation of the information, content or material by any party
            for any purpose.
          </p>

          <div className={s.mainContentTopic}>11. Copyright Complaints</div>
          <p className={s.pointParagraph}>
            In accordance with the Digital Millennium Copyright Act
            (&quot;DMCA&quot;) and other applicable laws, Raise may also
            terminate or suspend the account of any member who infringes any
            intellectual property rights of a third party, whether a repeat
            infringer or not. If you believe that any user content on Raise
            infringes upon any copyright that you own or otherwise control, you
            may file a notice of such infringement with our Designated DMCA
            Agent identified below, with the following information in writing:
            (i) a physical signature of the person authorized to act on behalf
            of the owner of the copyright interest; (ii) a description of the
            copyrighted work that you claim has been infringed upon; (iii) a
            description of where the material that you claim is infringing is
            located on the Site; (iv) your address, telephone number, and email
            address; (v) a statement by you that you have a good-faith belief
            that the disputed use is not authorized by the copyright owner, its
            agent, or the law; and (vi) a statement by you, made under penalty
            of perjury, that the above information in your notice is accurate
            and that you are the copyright owner or authorized to act on the
            copyright owner&apos;s behalf.
          </p>

          <p>
            Designated DMCA Agent: Attn: DMCA Copyright Agent, Raise | Raise
            Marketplace, LLC Legal Department, 2045 West Grand Avenue, Suite B
            #61404, Chicago, Illinois 60612, Email: legal@raise.com
          </p>

          <div className={s.mainContentTopic}>12. Links to Other Websites</div>
          <p className={s.pointParagraph}>
            Raise may link to third-party websites, services, and mobile
            applications ( &quot;Other Websites&quot; ). These links are
            provided as a convenience to you and not because we endorse these
            Other Websites. We are not responsible for the content or the
            accuracy of materials on these Other Websites, or the privacy
            practices of these Other Websites. If you decide to access any Other
            Website, you do so at your own risk and we are not responsible for
            any damages or liabilities caused or alleged to be caused by any use
            of the Other Websites.
          </p>

          <div className={s.mainContentTopic}>13. Your Privacy</div>
          <p className={s.pointParagraph}>
            We take the privacy of your personal data seriously. We encourage
            you to read our{" "}
            <Link to="/earn/privacy-policy" className={s.link}>
              Privacy Policy
            </Link>{" "}
            for disclosures about how we may collect, use and share personal
            data and the choices you may have with respect to the data we may
            collect and use. Our{" "}
            <Link to="/earn/privacy-policy" className={s.link}>
              Privacy Policy
            </Link>
            is incorporated into these Terms of Use and is available here:
            <Link to="/earn/privacy-policy" className={s.link}>
              Privacy Policy
            </Link>
            .
          </p>

          <div className={s.mainContentTopic}>
            14. Electronic Communications
          </div>
          <p className={s.pointParagraph}>
            When you use Raise or otherwise send emails to Raise, you are
            communicating with us electronically and you consent to receive
            electronic communications related to your use of Raise, including
            push notifications. We will communicate with you by email, push
            notification or by providing notices on our mobile applications. You
            agree that any communication we may send you via email or push
            notification will satisfy any legal requirement that any such
            communication be in writing. Any notice delivery by Raise via email
            will be effective when sent to the email address you provided to
            Raise or from which you otherwise email us. You further agree that
            you are responsible for maintaining an active email account on file
            with your Member Account and for any spam-filters (or similar
            content blockers) that may impact a Raise communication to you.
          </p>

          <div className={s.mainContentTopic}>
            15. Disclaimer of Warranties & Waiver of Claims
          </div>
          <p className={s.pointParagraph}>
            YOU EXPRESSLY AGREE THAT YOU ARE USING Raise AND ANY SERVICES
            OFFERED BY US AT YOUR OWN RISK AND THAT Raise AND SERVICES AVAILABLE
            ON Raise ARE PROVIDED &quot;AS-IS&quot; AND
            &quot;AS-AVAILABLE&quot;. TO THE MAXIMUM EXTENT ALLOWED UNDER
            APPLICABLE LAW, Raise DISCLAIMS ANY AND ALL REPRESENTATIONS,
            WARRANTIES AND CONDITIONS, WHETHER EXPRESS, IMPLIED OR STATUTORY, AS
            TO THE OPERATION OF Raise, INFORMATION CONTAINED ON Raise OR ANY OF
            THE GIFT CARDS OR OTHER OFFERINGS AVAILABLE ON Raise OR ANY OTHER
            PROGRAMS AVAILABLE OR ACCESSIBLE THROUGH Raise, INCLUDING WITHOUT
            LIMITATION, ANY IMPLIED WARRANTIES NON-INFRINGEMENT,
            MERCHANTABILITY, SUITABILITY, UP-TIME, OR FITNESS FOR A PARTICULAR
            PURPOSE. Raise MAKES NO WARRANTY THAT Raise WILL BE UNINTERRUPTED,
            SECURE, VIRUS FREE OR ERROR FREE. NO ORAL OR WRITTEN INFORMATION OR
            ADVICE GIVEN BY Raise OR ANY REPRESENTATIVE OF Raise WILL CREATE A
            WARRANTY OR OBLIGATION ON THE PART OF Raise OR SUPERCEDE ANY OF
            THESE TERMS OF USE.
          </p>

          <p>
            Raise IS NOT THE ISSUER OF THE GIFT CARDS SOLD ON Raise AND IS NOT
            RESPONSIBLE FOR WHETHER OR HOW A PARTICULAR MERCHANT HONORS SUCH
            GIFT CARDS, PARTICULARLY IN THE CIRCUMSTANCE WHEN A MERCHANT GOES
            OUT OF BUSINESS OR IS IN BANKRUPTCY OR LIQUIDATION. TO THE EXTENT
            PERMITTED UNDER APPLICABLE LAW, YOU AGREE THAT YOU WILL NOT SEEK
            RECOURSE AGAINST (AND HEREBY WAIVE ANY AND ALL CLAIMS AGAINST) Raise
            IN THE CIRCUMSTANCE THAT A MERCHANT DOES NOT HONOR A GIFT CARD
            BECAUSE IT IS UNABLE TO PAY ITS DEBTS, GOES OUT OF BUSINESS,
            LIQUIDATES, GOES BANKRUPT, OR OTHERWISE REORGANIZES. FURTHERMORE,
            Raise IS NOT RESPONSIBLE FOR ANY LOSS OR DAMAGE RESULTING FROM LOST
            OR STOLEN GIFT CARDS IN YOUR MEMBER ACCOUNT OR USE OF YOUR Raise
            ACCOUNT WITHOUT YOUR PERMISSION AND YOU HEREBY AGREE TO WAIVE ALL
            CLAIMS AGAINST Raise RELATED TO SUCH MATTERS.
          </p>

          <div className={s.mainContentTopic}>16. Limitation of Liability</div>
          <p className={s.pointParagraph}>
            TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT
            SHALL Raise, ITS SUBSIDIARIES OR AFFILIATES OR ANY OF THEIR
            RESPECTIVE OFFICERS, EMPLOYEES, AGENTS, MERCHANTS, LICENSORS OR
            PARTNERS, BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES, INCLUDING, BUT NOT
            LIMITED TO, DAMAGES ON ACCOUNT OF LOST PROFITS, LOSS OF DATA,
            BUSINESS INTERRUPTION, OR LOST BUSINESS ARISING OUT OF, RELATED TO,
            OR IN CONNECTION WITH (I) THE USE OF Raise, THE SITE AND/OR MEMBER
            CONTENT, INCLUDING ANY PERSONAL INFORMATION, (II) THE USE OF THE
            SERVICES ON Raise (OR INABILITY TO USE THE SERVICES), (III) THE
            MARKETING, PURCHASE OR SALE OF ANY GIFT CARDS ON Raise, AND (IV) ANY
            SERVICES OR OFFERINGS PROVIDED BY ANY THIRD PARTIES ACCESSIBLE FROM
            OR THROUGH Raise, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF
            LIABILITY(INCLUDING, CONTRACT, TORT, WARRANTY, STRICT LIABILITY,
            ETC.) AND EVEN IF Raise WAS MADE AWARE OF THE POSSIBILITY OF SUCH
            DAMAGES. IN NO EVENT SHALL Raise&apos;s TOTAL LIABILITY TO YOU,
            WHETHER IN CONTRACT, TORT, WARRANTY OR ANY OTHER THEORY OF LAW,
            ARISING UNDER THESE TERMS OF USE OR ARISING OUT OF OR RELATED TO THE
            USE OR INABILITY TO USE Raise AND/OR SERVICE PROVIDED VIA Raise
            EXCEED $200 IN THE AGGREGATE. YOU AGREE THAT ANY CAUSE OF ACTION
            HEREUNDER MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF
            ACTION ACCRUES OR THE CAUSE OF ACTION SHALL BE PERMANENTLY BARRED.
            BECAUSE CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG
            AN IMPLIED WARRANTY LASTS IN CERTAIN CONTEXTS, ALL OR A PORTION OF
            THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
          </p>

          <div className={s.mainContentTopic}>
            17. Indemnification & Release
          </div>
          <p className={s.pointParagraph}>
            You agree to defend, indemnify and hold us (and our affiliates,
            subsidiaries and our and their respective officers, directors,
            employees and agents (collectively with Raise Marketplace, LLC. the
            &quot;Indemnified Parties&quot;)) harmless from any claim or demand
            made by any third party due to or arising out of or related to: (i)
            your breach of these Terms of Use, (ii) your improper use of the
            Raise or Raise&apos;s services, (iii) your violation of any third
            parties&apos; intellectual property rights, (iv) your intentional
            misconduct, fraudulent acts or gross negligence, (v) any content you
            provide on Raise or (vi) your violation of any applicable law,
            including any applicable tax laws. You agree to pay any and all
            losses, costs, damages and expenses (including reasonable legal fees
            and expenses) (collectively &quot;Damages&quot;) for which any
            Indemnified Party is found liable in respect of any such claim or
            demand and to reimburse each Indemnified Party immediately for any
            Damages that the Indemnified Party has directly incurred, suffered
            or paid. You hereby recognize that Raise is not the issuer of any
            Merchant Gift Card sold on Raise. To the extent permitted under
            applicable law, you agree to release Raise from any claim or
            liability arising out of or related to (x) how and whether a
            Merchant honors its Merchant Gift Card(s) purchased on Raise and (z)
            any Merchant&apos;s failure to honor a particular Merchant Gift Card
            purchased by you on Raise.
          </p>

          <div className={s.mainContentTopic}>18. Legal Disputes</div>
          <p className={s.pointParagraph}>
            a. Binding Arbitration. By using Raise and agreeing to these Terms
            of Use, you agree that any claim, dispute, or controversy you may
            have against Raise arising out of, relating to, or connected in any
            way with these Terms of Use, Raise Cash Terms, this Site, or the
            purchase or sale of any Gift Cards or any other services offered on
            Raise (a &quot;Dispute&quot; ), shall be resolved exclusively by
            final and binding arbitration administered by the American
            Arbitration Association ( &quot;AAA&quot; ) and conducted before a
            single arbitrator pursuant to the applicable Rules and Procedures
            established by AAA, including, without limitation, the AAA
            Supplementary Procedures for Consumer Related Disputes, available at
            http://www.adr.org. ( &quot;Rules and Procedures&quot; ); except
            that you may bring a qualifying claim over a Dispute in a small
            claims court. The provisions of this Section 14 shall constitute
            your and Raise&apos;s written agreement to arbitrate Disputes under
            the Federal Arbitration Act (&quot;Dispute Agreement&quot;) and that
            you and Raise are each giving up the right to go to court and have a
            Dispute heard by a judge or jury (except for the limited exclusion
            above in a small claims court or in the context of injunctive relief
            permitted below).
          </p>

          <p>
            You agree further that: (A) the arbitration shall be held at a
            location determined by AAA pursuant to the Rules and Procedures
            (provided that such location is reasonably convenient for you), or
            at such other location as may be mutually agreed upon by you and
            Raise; (B) the arbitrator shall apply Illinois law consistent with
            the Federal Arbitration Act and applicable statutes of limitations,
            and shall honor claims of privilege recognized at law; (C) there
            shall be no authority for any claims to be arbitrated on a class or
            representative basis; arbitration can decide only your and/or
            Raise&apos;s individual claims; and the arbitrator may not
            consolidate or join the claims of other persons or parties who may
            be similarly situated; and (D) in the event that you are able to
            demonstrate that the costs of arbitration will be prohibitive as
            compared to the costs of litigation, Raise will pay as much of your
            filing, arbitration and hearing fees in connection with the
            arbitration as the arbitrator deems necessary (not to exceed
            $10,000) to prevent the arbitration from being cost-prohibitive.
            Raise agrees not to seek attorney&apos;s fees and costs in
            arbitration unless the arbitrator determines the Dispute was
            frivolous. The arbitration shall be conducted based on written
            submission unless you request and/or the arbitrator determines that
            a telephone or in-person hearing is necessary.
          </p>

          <p>
            (b) No Class Action. We each agree that we shall bring any Dispute
            against the other in our respective individual capacities and not as
            a plaintiff or class member of a purported class, representative
            proceeding or as an association. We each agree that any Dispute will
            be arbitrated only on an individual basis and not in a class,
            consolidated or representative action. The arbitrator may not vary
            these terms.
          </p>

          <p>
            (c) Choice of Law & Forum. Any and all Disputes shall be governed by
            the laws of the State of Illinois without regard to any conflicts of
            laws provisions. In the event any Dispute were to proceed in court,
            you agree that (i) the federal and state courts of the state of
            Illinois and Cook County shall have exclusive jurisdiction to
            adjudicate any such Dispute, (ii) you and Raise irrevocably consent
            and submit to the exclusive personal jurisdiction and venue of such
            courts for the resolution of such Disputes and (iii) you and Raise
            agree to irrevocably waive any right to a trial by jury.
          </p>

          <p>
            (d) Injunctive Relief. Notwithstanding anything to the contrary in
            this Dispute Agreement, either party may bring suit in court seeking
            an injunction or other equitable relief arising out of or related to
            the infringement of a party&apos;s intellectual property or any
            conduct that violates Section 5 of these Terms of Use.
          </p>

          <p>
            (e) Severability. If Section 16(b) above or Section 16(a),(c) above
            is ruled to be unenforceable, then Section 16(a) shall be deemed
            unenforceable, but the rest of this Section 16 shall remain in full
            effect.
          </p>

          <div className={s.mainContentTopic}>20. Miscellaneous Provisions</div>
          <p className={s.pointParagraph}>
            (a) Force Majeure Raise shall be excused from performance under
            these Terms of Use, and the Raise Cash Terms, to the extent it is
            delayed or prevented from performing, as a result of any event or
            series of events caused by: (i) weather conditions or elements of
            nature or acts of god, (ii) acts of war, terrorism, insurrection,
            riot, political unrest, (iii) labor strikes, (iv) embargoes, (v)
            error or disruption to computer hardware, networks or software
            failures or of third party providers or (vi) other causes beyond the
            reasonable control of Raise.
          </p>

          <p>
            (b) Entire Agreement These Terms of Use and the Raise Cash,
            including the Privacy Policy and other terms incorporated by
            reference, constitute the entire agreement and understanding between
            you and Raise with respect to the subject matter covered and
            supersedes any prior agreements, whether in writing or oral, with
            respect to such subject matter.
          </p>

          <p>
            (c) Assignment These Terms of Use and the Raise Cash Terms are not
            assignable, transferable or sublicensable by you except with
            Raise&apos;s prior written consent. Raise may transfer, assign or
            delegate these Terms of Use or the Raise Cash Terms and its rights
            and obligations hereunder without consent.
          </p>

          <p>
            (d) Severability & Subject Headings To the extent that any term is
            deemed invalid or unenforceable by a court of law with jurisdiction
            over the matter, that term shall be severable and not affect the
            validity of remaining terms. The section headings used herein are
            for convenience only and shall be of no legal force or effect.
          </p>

          <p>
            (e) Notice Any notice to Raise that is required or permitted by
            these Terms of Use or the Raise Cash Terms shall be in writing and
            shall be deemed effective upon receipt, when sent by confirmed email
            to legal@raise.com, when delivered in person by nationally
            recognized overnight courier, or when mailed by first class,
            registered or certified mail, postage prepaid, to Raise | Raise
            Marketplace, LLC, 2045 West Grand Avenue, Suite B #61404, Chicago,
            IL 60612, Attn: Legal.
          </p>
        </div>
      </div>
    </div>
  );
};
