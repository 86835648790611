import { INTERNAL_PATHS, EXTERNAL_LINKS } from "routes/paths";

const { ABOUT_US, RAISE_APP, API_SOLUTIONS, PRESS, EARN } = INTERNAL_PATHS;
const { GCX_MARKETPLACE } = EXTERNAL_LINKS;

export const FOOTER_MENU_LIST = [
  {
    groupTitle: "Products",
    items: [
      {
        label: "Raise App",
        link: RAISE_APP,
      },
      {
        label: "API Solutions",
        link: API_SOLUTIONS,
      },
      {
        label: "GCX",
        link: GCX_MARKETPLACE,
      },
      {
        label: "Earn",
        link: EARN,
      },
    ],
  },
  {
    groupTitle: "About",
    items: [
      {
        label: "About Raise",
        link: ABOUT_US,
      },
      {
        label: "Press",
        link: PRESS,
      },
    ],
  },
  // TODO add this option when the link was avaiable
  // {
  //   groupTitle: "Resources",
  //   items: [
  //     {
  //       label: "Litepaper",
  //       link: "",
  //     },
  //   ],
  // },
];
