import { Container } from "components/container";
import { RaiseLogo } from "components/raise-logo";
import { Typography } from "components/typography";
import { Copyright } from "./copyright";
import { FooterMenu } from "./footer-menu";
import { SocialLinks } from "./social-links";
import { useAppContext } from "context/AppContext";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import "./index.scss";

export const Footer = () => {
  const { width } = useWindowDimensions();
  const { themeView } = useAppContext();
  const isGreaterThanTablet = width >= 768;
  return (
    <footer className="footer" id="footer" data-theme={themeView}>
      <Container customClassName="footer-content-area">
        <div className="raise-info-area">
          <RaiseLogo isShortVersion />
          <Typography tag="p" variant="body-lg" customClassname="footer-slogan">
            The future of digital rewards starts with Raise
          </Typography>
        </div>
        <div className="footer-menu-area">
          <FooterMenu />
        </div>
        {isGreaterThanTablet && <SocialLinks />}
      </Container>
      <Copyright />
    </footer>
  );
}
