import { EXTERNAL_LINKS } from "routes/paths";
import "./index.scss";

export const NotificationBanner = () => {

  return (
    <div className="notification-banner">
      <div className="click-area">
        <a
          href={EXTERNAL_LINKS.GCX_MARKETPLACE}
          target="_blank"
          rel="noreferrer"
        >
          <p className="title">Raise Marketplace has moved!</p>
          <p className="subtitle">Click here to check out the all-new GCX.</p>
        </a>
      </div>
    </div>
  );
};
