export const subscribe = async (email) => {
  try {
    const response = await fetch(`/earn/subscribe?email=${email}`)
    const data = await response.json()
    if (response.status === 200) return true
    return data
  } catch (err) {
    window.ra({
      code: 'WALLET_SUBSCRIBE_FAILURE',
      content: { error: err.toString() },
      type: 'error',
      source: 'newsletter.js',
      sourceMethod: 'subscribe',
    })
    console.error('Error subscribing to sailthru:', err)
  }
}
