import { createContext, useCallback, useContext, useState } from "react";
import { THEME_VIEW } from "../constants/themeView";

const initialState = {
  themeView: THEME_VIEW.CONSUMER,
  isConsumerView: true,
  toggleThemeView: () => null,
};

const AppContext = createContext(initialState);

const AppProvider = ({ children }) => {
  const [themeView, setThemeView] = useState(initialState.themeView);

  const isConsumerView = themeView === THEME_VIEW.CONSUMER;

  const toggleThemeView = useCallback(() => {
    setThemeView(isConsumerView ? THEME_VIEW.BUSINESS : THEME_VIEW.CONSUMER);
  }, [isConsumerView]);

  return (
    <AppContext.Provider
      value={{
        themeView,
        isConsumerView,
        toggleThemeView,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { AppProvider, useAppContext };
