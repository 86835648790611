import { AppProvider } from "./context/AppContext";
import { Header } from "components/header";
import { AppRouter } from "routes";
import { BrowserRouter } from "react-router-dom";
import { Footer } from "components/footer";

const App = () => {
  return (
    <BrowserRouter>
      <AppProvider>
        <Header />
        <AppRouter />
        <Footer />
      </AppProvider>
    </BrowserRouter>
  );
};

export default App;
