import "./index.scss";
import { Typography } from "components/typography";
import { Button } from "components/button";
import { ReactComponent as CloseIcon } from "assets/products-card-close.svg";
import { redirectTo } from "services/redirect";

export const ProductsCarouselCard = ({
  onFrontCardClick,
  onCloseButtonClick,
  overlay,
  cover,
  icon,
  title,
  subtitle,
  description,
  linkTo: link,
  isNewTab,
}) => {
  const handleButtonClick = () => redirectTo({ link, isNewTab });

  const FrontCard = () => (
    <div className="card-front" onClick={onFrontCardClick}>
      <div className="image-container">
        <img src={cover} alt="Product" />
      </div>
      <Typography tag="h3" variant="title-xsm" semibold>
        {title}
      </Typography>
      <Typography variant="body-lg">{subtitle}</Typography>
    </div>
  );

  const BackCard = () => (
    <div className="card-back">
      {overlay && (
        <Button
          variant="secondary"
          customClassName="close-button"
          onClick={onCloseButtonClick}
        >
          <CloseIcon />
        </Button>
      )}
      <img src={icon} alt="Product icon" />
      <Typography tag="h3" variant="title-xsm" semibold>
        {title}
      </Typography>
      <Typography variant="body-lg">{description}</Typography>
      <Button customClassName="cta-button" onClick={handleButtonClick}>
        <Typography variant="body-md">Learn more</Typography>
      </Button>
    </div>
  );

  return overlay ? (
    <div className="products-carousel-card overlay">
      <div className="card-inner">
        <BackCard />
      </div>
    </div>
  ) : (
    <div className="products-carousel-card">
      <div className="card-inner">
        <FrontCard />
        <BackCard />
      </div>
    </div>
  );
};
