/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import adidas from "assets/earn/adidas.png";
import airbnb from "assets/earn/airbnb.png";
import appStoreDark from "assets/earn/app-store-dark.png";
import bulletCheck from "assets/earn/bullet.png";
import dominos from "assets/earn/dominos.png";
import doordash from "assets/earn/doordash.png";
import earnTile from "assets/earn/earn-pay.png";
import favicon from "assets/earn/favicon.ico";
import heroDesktop from "assets/earn/hero-device-desktop.png";
import heroMobile from "assets/earn/hero-device-mobile.png";
import heroTablet from "assets/earn/hero-device-tablet.png";
import homeDeopt from "assets/earn/home-depot.png";
import payTile from "assets/earn/pay-pay.png";
import playStoreDark from "assets/earn/play-store-dark.png";
import qrCode from "assets/earn/qr-code.jpeg";
import repeatTile from "assets/earn/repeat-pay.png";
import sephora from "assets/earn/sephora.png";
import shopTile from "assets/earn/shop-pay.png";
import tilesDesktop from "assets/earn/tiles-desktop.png";
import tilesMobile from "assets/earn/tiles-mobile.png";
import tilesTablet from "assets/earn/tiles-tablet.png";
import uber from "assets/earn/uber.png";
import walmart from "assets/earn/walmart.png";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { subscribe } from "./mailer-service";
import Slider from "./Slider";
import s from "./index.module.css";

const validateEmail = (fieldValue) => {
  if (!fieldValue.length) {
    return false;
  }
  const validChars =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
  const hasValidChars = validChars.test(String(fieldValue).toLowerCase());

  return hasValidChars;
};

const HeroSection = () => {
  return (
    <div className={s.heroContainer}>
      <div className={s.heroSection}>
        <div className={s.heroTitle}>
          Everyday earnings on everyday spending
        </div>
        <div className={s.heroSubtitle}>
          The new Raise app is the most rewarding way to pay with limitless
          earning potential.
        </div>
        <img
          src={heroMobile}
          alt="hero-mobile"
          className={cn(s.heroMobile, s.mobile)}
        />
        <div className={cn(s.heroButtons, s.mobile, s.tablet)}>
          <a href="https://slide.app.link/signin">
            <img
              src={appStoreDark}
              alt="app-store-dark"
              className={s.heroAppStore}
            />
          </a>
          <a href="https://slide.app.link/signin">
            <img
              src={playStoreDark}
              alt="play-store-dark"
              className={s.heroPlayStore}
            />
          </a>
        </div>
        <img
          src={heroTablet}
          alt="hero-tablet"
          className={cn(s.heroTablet, s.tablet)}
        />
        <img
          src={qrCode}
          alt="qr-code"
          className={cn(s.heroQrCode, s.desktop)}
        />
        <div className={cn(s.desktop, s.heroSubtext)}>
          <span className={s.highlight}>New!</span>&nbsp; Download the app to
          start earning
        </div>
        <img
          src={heroDesktop}
          alt="hero-tablet"
          className={cn(s.heroDesktop, s.desktop)}
        />
      </div>
    </div>
  );
};

const EarningsSection = () => {
  return (
    <div className={s.earningsSection}>
      <div className={s.earningsTitle}>Meet your earnings ally</div>
      <div className={s.earningsSubtitle}>
        Get <span className={s.earningsBold}>up to 10% back</span> at your
        favorite brands when you check Raise first. And when we add our
        exclusive boosts, it gets even better.
      </div>
      <div className={s.brandsSection}>
        <img src={adidas} alt="adidas" className={s.brand} />
        <img src={homeDeopt} alt="home-depot" className={s.brand} />
        <img src={doordash} alt="doordash" className={s.brand} />
        <img src={sephora} alt="sephora" className={s.brand} />
        <img src={walmart} alt="walmart" className={s.brand} />
        <img src={airbnb} alt="aribnb" className={s.brand} />
        <img src={uber} alt="uber" className={s.brand} />
        <img src={dominos} alt="dominos" className={s.brand} />
      </div>
    </div>
  );
};

const NewSection = () => {
  return (
    <div className={s.newContainer}>
      <div className={s.newSection}>
        <div className={s.newSectionTitle}>What&apos;s new with Raise?</div>
        <div className={s.bullet}>
          <img src={bulletCheck} alt="bullet-check" className={s.bulletCheck} />
          <div className={s.bulletText}>
            A NEW shopping experience with more ways to boost your earnings
          </div>
        </div>
        <div className={s.bullet}>
          <img src={bulletCheck} alt="bullet-check" className={s.bulletCheck} />
          <div className={s.bulletText}>Over 60 NEW brands added</div>
        </div>
        <div className={s.bullet}>
          <img src={bulletCheck} alt="bullet-check" className={s.bulletCheck} />
          <div className={s.bulletText}>
            Raise Cash is available instantly after you earn it
          </div>
        </div>
        <div className={s.bullet}>
          <img src={bulletCheck} alt="bullet-check" className={s.bulletCheck} />
          <div className={s.bulletText}>
            Real-time balance checking at over 300 brands
          </div>
        </div>
        <div className={s.bullet}>
          <img src={bulletCheck} alt="bullet-check" className={s.bulletCheck} />
          <div className={s.bulletText}>
            All NEW wallet to track all your earnings and spending
          </div>
        </div>
        <img
          src={tilesMobile}
          alt="tiles-mobile"
          className={cn(s.tilesMobile, s.mobile)}
        />
        <img
          src={tilesTablet}
          alt="tiles-mobile"
          className={cn(s.tilesTablet, s.tablet)}
        />
        <img
          src={tilesDesktop}
          alt="tiles-mobile"
          className={cn(s.tilesDesktop, s.desktop)}
        />
      </div>
      <Slider />
    </div>
  );
};

const RaisePaySection = () => {
  return (
    <div className={s.raisePaySection}>
      <div className={s.raisePayTitle}>
        It&apos;s so easy to pay <br className={s.mobile} />
        with Raise
      </div>
      <div className={s.raisePayTileContainer}>
        <div className={s.raisePayTileSection}>
          <div className={s.raisePayTile}>
            <img src={shopTile} alt="shop-tile" className={s.tileImage} />
            <div className={s.tileContent}>
              <div className={s.tileTitle}>Shop</div>
              <div className={s.tileText}>
                Choose from hundreds of top brands and select an amount to spend
              </div>
            </div>
          </div>
          <div className={s.raisePayTile}>
            <img src={payTile} alt="pay-tile" className={s.tileImage} />
            <div className={s.tileContent}>
              <div className={s.tileTitle}>Pay</div>
              <div className={s.tileText}>
                Use the serial number or bar code provided for hassle-free
                payments
              </div>
            </div>
          </div>
          <div className={s.raisePayTile}>
            <img src={earnTile} alt="earn-tile" className={s.tileImage} />
            <div className={s.tileContent}>
              <div className={s.tileTitle}>Earn</div>
              <div className={s.tileText}>
                Rack up your Raise Cash and boost your earnings
              </div>
            </div>
          </div>
          <div className={s.raisePayTile}>
            <img src={repeatTile} alt="repeat-tile" className={s.tileImage} />
            <div className={s.tileContent}>
              <div className={s.tileTitle}>Repeat</div>
              <div className={s.tileText}>
                Redeem your Raise Cash earnings on your next purchase
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const JoinWaitlist = ({
  email,
  handleEmailUpdate,
  isEmailValid,
  waitlistRef,
  handleSubmit,
  apiSuccess,
}) => {
  return (
    <div className={s.waitlistContainer} ref={waitlistRef}>
      <div className={s.waitlistTitle}>Good habits start here</div>
      <div className={s.waitlistText}>
        Never miss out. Get updates on special offers, new brand arrivals, and
        the latest features — all delivered to your inbox.
      </div>
      <div className={s.waitlistForm}>
        <input
          type="email"
          onChange={handleEmailUpdate}
          className={cn(s.waitlistInput, {
            [s.errorBorder]: email?.length && !isEmailValid,
          })}
          placeholder="Your email"
          value={email}
          name="email"
          label="email"
        />
        <button
          className={s.waitlistButton}
          disabled={email.length && !isEmailValid}
          onClick={handleSubmit}
        >
          Sign up
        </button>
      </div>
      {apiSuccess && (
        <div className={s.waitlistSuccess}>Success! You&apos;re in.</div>
      )}
    </div>
  );
};

export const Earn = () => {
  const [isEmailValid, setEmailValidity] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const waitlistRef = useRef(null);

  const handleEmailUpdate = ({ target: { value } }) => {
    setEmail(value);
    if (value !== "Success!") setEmailValidity(validateEmail(value));
    setApiSuccess(false);
  };

  const handleSubmit = async () => {
    if (email.length) {
      setLoading(true);
      await subscribe(email);
      setEmail("");
      setEmailValidity(true);
      setLoading(false);
      setApiSuccess(true);
    }
  };

  useEffect(() => {
    const { ref } = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );

    if (ref === "email") {
      setTimeout(() => {
        waitlistRef?.current?.scrollIntoView();
      }, 1000);
    }
  }, [waitlistRef]);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = favicon;
  }, []);

  return (
    <div className={s.page}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <title>Raise Earn</title>
      </Helmet>
      <div className={s.container}>
        <HeroSection />
        <EarningsSection />
        <NewSection />
        <RaisePaySection />
        <JoinWaitlist
          email={email}
          isEmailValid={isEmailValid}
          handleEmailUpdate={handleEmailUpdate}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          waitlistRef={waitlistRef}
          apiSuccess={apiSuccess}
        />
      </div>
    </div>
  );
};

