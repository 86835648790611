import { ToggleSwitch } from "./toggle-switch";
import { ContentArea } from "./content-area";
import { SecondaryAreaContent } from "./secondary-area-content";
import { Container } from "components/container";
import "./index.scss";

export const HeroScene = () => {
  return (
    <div className="hero-scene" id="hero-scene">
      <div className="hero-scene-cover">
        <Container>
          <div className="toggle-area">
            <ToggleSwitch />
          </div>
          <div className="content-area-container">
            <ContentArea />
            <SecondaryAreaContent />
          </div>
        </Container>
      </div>
    </div>
  );
};
