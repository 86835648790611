import { Typography } from "components/typography";
import { FOOTER_MENU_LIST } from "./utils/footer-menu-items";
import "./index.scss";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import { SocialLinks } from "../social-links";

export const FooterMenu = () => {
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  return (
    <div className="footer-menu">
      {FOOTER_MENU_LIST.map((menuList) => (
        <div className="menu-items" key={menuList.groupTitle}>
          <Typography variant="body-lg" semibold customClassname="group-title">
            {menuList.groupTitle}
          </Typography>
          <ul>
            {menuList.items.map((item) => (
              <li key={item.label}>
                <a href={item.link} className="anchor-menu-item">
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
      {isMobile && <SocialLinks />}
    </div>
  );
};
