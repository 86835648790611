import "./index.scss";
import { Container } from "components/container";
import { Typography } from "components/typography";
import { INFORMATIONAL_TEXT } from "./utils/informational-text";
import { useWindowDimensions } from "hooks/useWindowDimensions";

export const InformationalText = () => {
  const { width } = useWindowDimensions();

  const isTabletOrBelow = width < 768;
  const displayText = INFORMATIONAL_TEXT[isTabletOrBelow ? "mobile" : "default"];

  return (
    <div className="informational-text">
      <Container>
        <Typography tag="h3" variant="title-md">
          {displayText[0]}
          <span className="pill">cutting-edge</span>
          {isTabletOrBelow ? <br /> : " "}
          <span className="pill">blockchain</span>
          {isTabletOrBelow ? " " : <br />}
          <span className="pill">technology</span>
          {displayText[1]}
        </Typography>
      </Container>
    </div>
  );
};
