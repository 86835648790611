import { Route, Routes } from "react-router-dom";
import { INTERNAL_PATHS } from "./paths";
import { HomePage } from "pages/home";
import { AboutUs } from "pages/about-us";
import { Press } from "pages/press";
import { Earn } from "pages/earn";
import { Terms } from "pages/terms";
import { PrivacyPolicy } from "pages/privacy-policy";
import { RaiseCashTerms } from "pages/raise-cash-terms";

const {
  HOME,
  ABOUT_US,
  PRESS,
  EARN,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICE,
  RAISE_CASH_TERMS,
  MISC
} = INTERNAL_PATHS;

export const AppRouter = () => (
  <Routes>
    <Route path={HOME} element={<HomePage />} />
    <Route path={ABOUT_US} element={<AboutUs />} />
    <Route path={PRESS} element={<Press />} />
    <Route path={EARN} element={<Earn />} />
    <Route path={TERMS_AND_CONDITIONS} element={<Terms />} />
    <Route path={PRIVACY_POLICE} element={<PrivacyPolicy />} />
    <Route path={RAISE_CASH_TERMS} element={<RaiseCashTerms />} />
    <Route path={MISC} element={<HomePage />} />
  </Routes>
);
