import { useCallback } from "react";

export const useHandleSlides = ({ currentSlideIndex, handleSlidePage }) => {
  const handleNext = useCallback(() => {
    handleSlidePage(currentSlideIndex + 1);
  }, [currentSlideIndex, handleSlidePage]);

  const handlePrevious = useCallback(() => {
    handleSlidePage(currentSlideIndex - 1);
  }, [currentSlideIndex, handleSlidePage]);

  return {
    handleNext,
    handlePrevious,
  };
};
