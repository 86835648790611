import "./index.scss";

export const CarouselDots = ({
  totalItems,
  activeSlideIndex,
  customClassName = ''
}) => {
  const items = new Array(totalItems).fill('');
  return (
    <div className={`carousel-dots ${customClassName}`}>
      {items.map((_, index) => {
        const isActive = activeSlideIndex === index;

        return (
          <div className={`dot ${isActive ? "active" : ""}`} key={index} />
        );
      })}
    </div>
  );
}
