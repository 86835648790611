import "./index.scss";
import { Button } from "components/button";
import { Typography } from "components/typography";
import { redirectTo } from "services/redirect";

export const CommunityButton = ({ name, linkTo: link, Icon }) => {
  const handleButtonClick = () => redirectTo({ link, isNewTab: true });

  return (
    <div className="community-button">
      <Typography variant="body-lg" semibold customClassname="name">
        {name}
      </Typography>
      <Button variant="secondary" onClick={handleButtonClick}>
        <Icon />
      </Button>
    </div>
  );
};
