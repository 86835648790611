import smartCard from "assets/images/future-slider/smartcard-experiencie.png";
import smartWallet from "assets/images/future-slider/smart-wallet.png";
import smartMessaging from "assets/images/future-slider/smart-messaging.png";
import raiseToken from "assets/images/future-slider/raise-token.png";

export const SLIDES_LIST = [
  {
    id: 1,
    title: "The SmartCard Experience",
    subtitle: "Revolutionizing Gift Cards",
    thumbnail: smartCard,
    text: "Unleash the potential of gift cards with our groundbreaking SmartCard. This programmable digital currency goes beyond the gift cards of today, offering customized shopping and rewards experiences. By integrating on-chain and off-chain data, the SmartCard boosts engagement thanks to personalized promotions and loyalty programs.",
  },
  {
    id: 2,
    title: "SmartWallet",
    subtitle: "The future of transactions",
    thumbnail: smartWallet,
    text: "Embrace the next level of digital transactions with our innovative SmartWallet, powered by SmartCard technology. Experience seamless interactions with permissionless access to airdrop promotions, connecting you directly with your favorite brands for unique offers and special promotions, all in one easy-to-use digital wallet.",
  },
  {
    id: 3,
    title: "SmartMessaging",
    subtitle: "Personalized engagement",
    thumbnail: smartMessaging,
    text: "Expand the way brands engage with consumers through our blockchain-based SmartMessaging platform. Leveraging the latest in AI and machine learning, we offer secure, in-app communications that deliver personalized offers and interactive content tailored to user preferences and behaviors, all designed to drive loyalty.",
  },
  {
    id: 4,
    title: "Raise Token",
    subtitle: "Powering innovation",
    thumbnail: raiseToken,
    text: "Discover the core of our ecosystem with the Raise Token, the governance and utility coin at the heart of the Raise blockchain platform.",
  },
];
