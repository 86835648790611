import { SOCIAL_LINKS_LIST } from "./utils/social-links-list";
import "./index.scss";

export const SocialLinks = () => {

  return (
    <div className="social-links-area">
      {SOCIAL_LINKS_LIST.map((item) => (
        <a href={item.link} target="_blank" rel="noreferrer" key={item.label}>
          <img src={item.image} alt={item.label} className="social-link-image" />
        </a>
      ))}
    </div>
  );
}
