import "./index.scss";
import { ReactComponent as RaiseLetter } from "assets/raise-logo-letter.svg";
import { ReactComponent as DiscordIcon } from "assets/discord.svg";
import { ReactComponent as TelegramIcon } from "assets/telegram.svg";
import { Container } from "components/container";
import { Typography } from "components/typography";
import { CommunityButton } from "./community-button";
import { EXTERNAL_LINKS } from "routes/paths";

export const CommunityBanner = () => (
  <div className="community-banner">
    <Container>
      <RaiseLetter className="background-letter" />
      <div className="community-banner-content">
        <Typography variant="title-xxl" semibold customClassname="title">
          Join our community
        </Typography>
        <Typography variant="body-lg" customClassname="description">
          Stay informed of exciting updates and join the conversation about the
          future of loyalty and rewards.
        </Typography>
        <div className="community-buttons">
          <CommunityButton
            name="Discord"
            linkTo={EXTERNAL_LINKS.DISCORD_COMMUNITY}
            Icon={DiscordIcon}
          />
          <hr />
          <CommunityButton
            name="Telegram"
            linkTo={EXTERNAL_LINKS.TELEGRAM_COMMUNITY}
            Icon={TelegramIcon}
          />
        </div>
      </div>
    </Container>
  </div>
);
