export const INFORMATIONAL_TEXT = {
  mobile: [
    `We’re harnessing the\nuntapped potential of gift cards\n& leveraging `,
    `\nto enhance consumer\nexperiences & create\nmore meaningful relationships\nbetween brands & consumers.`,
  ],
  default: [
    `We’re harnessing the untapped potential of gift cards\nand leveraging `,
    ` to enhance consumer experiences\nand create more meaningful relationships between\nbrands and consumers.`,
  ],
};
