import { useAppContext } from "context/AppContext";
import { HeroSceneDescription } from "../hero-scene-description";
import { Typography } from "components/typography";
import { QRCode } from "../qr-code";
import { Button } from "components/button";
import { redirectTo } from "services/redirect";
import { INTERNAL_PATHS } from "routes/paths";
import "./index.scss";

export const ContentArea = () => {
  const { isConsumerView } = useAppContext();
  return (
    <div className="content-area">
      {isConsumerView ? (
        <HeroSceneDescription
          title="Meet your earnings ally"
          description={
            <>
              <Typography tag="p" variant="body-md">
                Download the Raise app and start earning
                <strong> up to 20% back </strong>
                instantly or visit{" "}
                <a href={INTERNAL_PATHS.RAISE_APP} className="raise-earn">
                  raise.com/earn
                </a>{" "}
                to learn more.
              </Typography>
            </>
          }
          footer={
            <>
              <QRCode />
              <Button
                customClassName="download-app-button"
                onClick={() =>
                  redirectTo({
                    link: INTERNAL_PATHS.RAISE_APP,
                  })
                }
              >
                Get the app
              </Button>
            </>
          }
          customClassName="consumer-content"
        />
      ) : (
        <HeroSceneDescription
          title="Unlocking loyalty & rewards through gift cards"
          description={
            <>
              <Typography
                tag="p"
                variant="body-md"
                customClassname="business-description"
              >
                Retain and engage your customers, clients, and employees with
                rewards they’ll actually get excited about.
              </Typography>
            </>
          }
          footer={
            <Button
              customClassName="learn-more-button"
              onClick={() =>
                redirectTo({
                  link: INTERNAL_PATHS.API_SOLUTIONS,
                })
              }
            >
              Learn more
            </Button>
          }
          customClassName="business-content"
        />
      )}
    </div>
  );
};
