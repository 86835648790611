import "./index.scss";

export const Button = ({
  variant,
  customClassName = "",
  children,
  ...props
}) => {
  return (
    <button className={`button ${variant} ${customClassName}`} {...props}>
      {children}
    </button>
  );
};
