import { useRef, useState } from "react";
import Slider from "react-slick";
import { CarouselDots } from "components/carousel-dots";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import { SLIDES_LIST } from "../utils/slides-list";
import { FutureSlideCard } from "../future-slide-card";
import { SliderArrows } from "../slider-arrows";
import "./index.scss";

export const SliderArea = () => {
  const { width } = useWindowDimensions();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const isBelowTabletLandscape = width <= 1024;
  const isMobile = width < 768;
  let sliderRef = useRef(null);

  const settings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "80px",
    centerMode: true,
    infinite: true,
    className: "future-slider-settings",
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          centerPadding: "12%",
        },
      },
      {
        breakpoint: 1023,
        settings: {
          centerPadding: "0px",
          centerMode: false,
          infinite: true,
        },
      },
    ],
  };

  const handleSlidePage = (newPage) => {
    if (!sliderRef) return;

    sliderRef.slickGoTo(newPage);
  };

  return (
    <>
      {isBelowTabletLandscape && (
        <CarouselDots
          activeSlideIndex={activeSlideIndex}
          totalItems={SLIDES_LIST.length}
          customClassName="future-slider-dots"
        />
      )}
      <Slider
        {...settings}
        ref={(slider) => {
          sliderRef = slider;
        }}
        afterChange={(newIndex) => setActiveSlideIndex(newIndex)}
      >
        {SLIDES_LIST.map((item) => (
          <FutureSlideCard
            {...item}
            key={item.id}
            arrowAreaComponent={
              !isMobile && (
                <SliderArrows
                  currentSlideIndex={activeSlideIndex}
                  handleSlidePage={handleSlidePage}
                />
              )
            }
          />
        ))}
      </Slider>
    </>
  );
};
