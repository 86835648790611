import { Container } from "components/container";
import { RaiseLogo } from "components/raise-logo";
import { useAppContext } from "context/AppContext";
import { useEffect, useState } from "react";
import "./index.scss";
import Menu from "./menu";

export const Header = () => {
  const [isDropdownOpen, setDropdown] = useState(false);
  const [pageOffset, setPageOffset] = useState(window.pageYOffset);
  const { themeView } = useAppContext();

  const isTopScreen = pageOffset === 0;

  useEffect(() => {
    const handleScroll = () => setPageOffset(window.scrollY);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const handleClick = () => {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-W4JB8CRR');
  }

  return (
    <header
      className={`header ${isDropdownOpen ? "dropdown-open disable-overflow" : "dropdown-closed"
        } ${isTopScreen ? "top-screen" : ""}`}
      data-theme={themeView}
      onClick={handleClick}
    >
      <Container>
        <RaiseLogo />
        <Menu />
      </Container>
      <div className="backdrop" onClick={() => setDropdown(false)} />
    </header>
  );
};
