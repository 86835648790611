import { Button } from "components/button";
import carouselArrow from "assets/carousel-arrow.svg";
import { useHandleSlides } from "./utils/useHandleSlides";
import "./index.scss";

export const SliderArrows = ({ currentSlideIndex, handleSlidePage }) => {
  const { handleNext, handlePrevious } = useHandleSlides({
    currentSlideIndex,
    handleSlidePage,
  });

  return (
    <div className="slider-arrows-container">
      <Button
        variant="primary"
        customClassName="arrow-button"
        onClick={handlePrevious}
      >
        <img src={carouselArrow} alt="Left arrow to previous benefit slide" />
      </Button>
      <Button
        variant="primary"
        customClassName="arrow-button right-arrow"
        onClick={handleNext}
      >
        <img src={carouselArrow} alt="Right arrow to next benefit slide" />
      </Button>
    </div>
  );
};
