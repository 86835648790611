import "./index.scss";
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import { CARD_LIST } from "./utils/card-list";
import { Container } from "components/container";
import { Typography } from "components/typography";
import { CarouselDots } from "components/carousel-dots";
import { ProductsCarouselCard } from "./products-carousel-card";

const OVERLAY_INITIAL_VALUE = CARD_LIST[0];

export const ProductsCarousel = () => {
  const { width } = useWindowDimensions();
  const [activeSlideIndex, setSlideIndex] = useState(0);
  const [overlayCard, setOverlayCard] = useState(OVERLAY_INITIAL_VALUE);
  const [isOverlayVisible, setOverlayVisibility] = useState(false);

  const isMobile = width < 1024;

  const settings = {
    dots: false,
    arrows: false,
    autoplay: isOverlayVisible ? false : true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    draggable: true,
    pauseOnHover: false,
    swipeToSlide: false,
  };

  const handleFrontCardClick = (props) => {
    setOverlayCard(props);
    setOverlayVisibility(true);
  };

  const hideOverlay = () => setOverlayVisibility(false);

  return (
    <div className="products-carousel">
      <Container>
        <Typography variant="title-md" semibold>
          Discover our products
        </Typography>
        {isMobile ? (
          <>
            <CarouselDots
              activeSlideIndex={activeSlideIndex}
              totalItems={CARD_LIST.length}
            />
            <Slider
              {...settings}
              afterChange={(newIndex) => setSlideIndex(newIndex)}
            >
              {CARD_LIST.map((props) => (
                <ProductsCarouselCard
                  key={props.title}
                  {...props}
                  onFrontCardClick={() => handleFrontCardClick(props)}
                />
              ))}
            </Slider>
            <div
              className={`products-carousel-overlay ${
                isOverlayVisible ? "visible disable-overflow" : "hidden"
              }`}
            >
              <ProductsCarouselCard
                {...overlayCard}
                overlay
                onCloseButtonClick={hideOverlay}
              />
              <div className="backdrop" onClick={hideOverlay} />
            </div>
          </>
        ) : (
          <div className="products-list">
            {CARD_LIST.map((props) => (
              <ProductsCarouselCard
                key={props.title}
                {...props}
                onFrontCardClick={() => handleFrontCardClick(props)}
              />
            ))}
          </div>
        )}
      </Container>
    </div>
  );
};
