import qrCodeImage from "assets/images/download-qr-code.png";
import { Typography } from "components/typography";
import { INTERNAL_PATHS } from "routes/paths";
import "./index.scss";

export const QRCode = () => {
  return (
    <div className="qr-code-container">
      <img
        src={qrCodeImage}
        alt="Get the app"
        className="qr-code"
        loading="lazy"
      />
      <Typography tag="p" variant="body-md">
        Download the Raise app and start earning
        <strong> up to 20% back </strong>
        instantly <br /> or visit{" "}
        <a href={INTERNAL_PATHS.RAISE_APP} className="raise-earn">
          raise.com/earn
        </a>{" "}
        to learn more.
      </Typography>
    </div>
  );
};
