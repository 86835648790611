import { Typography } from "components/typography";
import "./index.scss";

export const FutureSlideCard = ({
  thumbnail,
  title,
  subtitle,
  text,
  arrowAreaComponent
}) => {
  return (
    <div className="future-slide-card">
      <div className="thumbnail-head">
        <img
          src={thumbnail}
          alt={`${title} thumbnail`}
          className="thumbnail-image"
        />
      </div>
      <div className="description-area">
        <div className="description">
          <Typography tag="h3" variant="title-sm" customClassname="subtitle">
            {subtitle}
          </Typography>
          <Typography tag="h2" variant="title-xxl" customClassname="title">
            {title}
          </Typography>
          <Typography tag="p" variant="body-lg" customClassname="text">
            {text}
          </Typography>
        </div>
        { !!arrowAreaComponent && arrowAreaComponent }
      </div>
    </div>
  );
};
