import cn from 'classnames'
import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import carousel1Mobile from '../../../assets/earn/carousel-1-mobile.png'
import carousel1Tablet from '../../../assets/earn/carousel-1-tablet.png'
import carousel2Mobile from '../../../assets/earn/carousel-2-mobile.png'
import carousel2Tablet from '../../../assets/earn/carousel-2-tablet.png'
import carousel3Mobile from '../../../assets/earn/carousel-3-mobile.png'
import carousel3Tablet from '../../../assets/earn/carousel-3-tablet.png'
import carousel4Mobile from '../../../assets/earn/carousel-4-mobile.png'
import carousel4Tablet from '../../../assets/earn/carousel-4-tablet.png'
import arrowLeft from '../../../assets/earn/left-chevron.png'
import arrowRight from '../../../assets/earn/right-chevron.png'
import earnStyles from '../index.module.css'
import s from './index.module.css'

const Slide1 = () => {
  return (
    <div className={s.slide}>
      <div className={s.slideTitle}>
        Buy more. Earn more. <br className={earnStyles.mobile} />
        It&apos;s that simple.
      </div>
      <img
        src={carousel1Mobile}
        alt="carousel-1-mobile"
        className={cn(s.slideImageMobile, earnStyles.mobile)}
      />
      <div className={s.slideContentContainer}>
        <div className={s.slideSubtitle}>Stock up on everyday essentials</div>
        <div className={s.slideSubtext}>
          Try that trending skincare item or restock on your favorites at
          Sephora. A $150 shopping trip{' '}
          <span className={s.slideSubtextBold}>earns you $9.80 back</span> when
          you pay with Raise.
        </div>
      </div>
      <img
        src={carousel1Tablet}
        alt="carousel-1-tablet"
        className={cn(
          s.slideImageTablet,
          earnStyles.tablet,
          earnStyles.desktop,
        )}
      />
    </div>
  )
}

const Slide2 = () => {
  return (
    <div className={s.slide}>
      <div className={s.slideTitle}>
        Buy more. Earn more. <br className={earnStyles.mobile} />
        It&apos;s that simple.
      </div>
      <img
        src={carousel2Mobile}
        alt="carousel-2-mobile"
        className={cn(s.slideImageMobile, earnStyles.mobile)}
      />
      <div className={s.slideContentContainer}>
        <div className={s.slideSubtitle}>More earnings, more doing</div>
        <div className={s.slideSubtext}>
          Feeling inspired to DIY? Make the most of your $500 building budget at
          Home Depot and{' '}
          <span className={s.slideSubtextBold}>get $10 back</span> to put
          towards your next project.
        </div>
      </div>
      <img
        src={carousel2Tablet}
        alt="carousel-2-tablet"
        className={cn(
          s.slideImageTablet,
          earnStyles.tablet,
          earnStyles.desktop,
        )}
      />
    </div>
  )
}

const Slide3 = () => {
  return (
    <div className={s.slide}>
      <div className={s.slideTitle}>
        Buy more. Earn more. <br className={earnStyles.mobile} />
        It&apos;s that simple.
      </div>
      <img
        src={carousel3Mobile}
        alt="carousel-3-mobile"
        className={cn(s.slideImageMobile, earnStyles.mobile)}
      />
      <div className={s.slideContentContainer}>
        <div className={s.slideSubtitle}>Travel more, spend smarter</div>
        <div className={s.slideSubtext}>
          Eyeing a getaway? Book a $2,000 Carnival Cruise through Raise and{' '}
          <span className={s.slideSubtextBold}>earn $160</span> to spend on
          food, fun and even your Uber to the airport.
        </div>
      </div>
      <img
        src={carousel3Tablet}
        alt="carousel-3-tablet"
        className={cn(
          s.slideImageTablet,
          earnStyles.tablet,
          earnStyles.desktop,
        )}
      />
    </div>
  )
}

const Slide4 = () => {
  return (
    <div className={s.slide}>
      <div className={s.slideTitle}>
        Buy more. Earn more. <br className={earnStyles.mobile} />
        It&apos;s that simple.
      </div>
      <img
        src={carousel4Mobile}
        alt="carousel-4-mobile"
        className={cn(s.slideImageMobile, earnStyles.mobile)}
      />
      <div className={s.slideContentContainer}>
        <div className={s.slideSubtitle}>Make earning a daily habit</div>
        <div className={s.slideSubtext}>
          Your daily coffee habit could{' '}
          <span className={s.slideSubtextBold}>
            earn you $107.49 by year&apos;s end
          </span>{' '}
          when you order your Panera Bread with Raise. That&apos;s a lot of free
          cups!
        </div>
      </div>
      <img
        src={carousel4Tablet}
        alt="carousel-4-tablet"
        className={cn(
          s.slideImageTablet,
          earnStyles.tablet,
          earnStyles.desktop,
        )}
      />
    </div>
  )
}

const SampleNextArrow = ({ onClick }) => {
  return (
    <button className={cn(s.arrowButton, s.buttonRight)} onClick={onClick}>
      <img src={arrowRight} alt="arrowRight" className={s.arrowRight} />
    </button>
  )
}

const SamplePrevArrow = ({ onClick }) => {
  return (
    <button className={cn(s.arrowButton, s.buttonLeft)} onClick={onClick}>
      <img src={arrowLeft} alt="arrowLeft" className={s.arrowLeft} />
    </button>
  )
}

const StyledSlider = () => {
  const settings = {
    dots: true,
    dotsClass: s.dots,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }
  return (
    <div className={s.container}>
      <div className={s.section}>
        <Slider {...settings}>
          <div className={s.slideContainer}>
            <Slide1 />
          </div>
          <div className={s.slideContainer}>
            <Slide2 />
          </div>
          <div className={s.slideContainer}>
            <Slide3 />
          </div>
          <div className={s.slideContainer}>
            <Slide4 />
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default StyledSlider
