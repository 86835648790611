import { Typography } from "components/typography";
import { Container } from "components/container";
import { INTERNAL_PATHS } from "routes/paths";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import "./index.scss";

const { TERMS_AND_CONDITIONS, PRIVACY_POLICE } = INTERNAL_PATHS;

export const Copyright = () => {
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const year = new Date().getFullYear();
  const separator = `${isMobile ? ' ' : ' | '}`;
  return (
    <div className="copyright">
      <Container>
        <Typography variant="body-sm" tag="p" customClassname="copyright-text">
          <span className="copyright-year">Copyright © {year} | All rights reserved</span>
          {separator}
          <a className="copyright-anchor" href={TERMS_AND_CONDITIONS}>
            Terms and Conditions
          </a>
          {" | "}
          <a className="copyright-anchor" href={PRIVACY_POLICE}>
            Privacy Policy
          </a>
        </Typography>
      </Container>
    </div>
  );
};
