import React, { useRef, useEffect } from 'react'
import s from './index.module.css'
import { NavLink } from 'react-router-dom'
import useMedia from 'react-use/lib/useMedia'

const EarnLinks = () => {
  const wrapper = useRef(null)
  const isMobile = useMedia('(max-width: 767px)')

  useEffect(() => {
    if (isMobile && window.location.pathname === '/earn/raisecashterms') {
      wrapper.current.scrollLeft += 100
    }
  }, [wrapper, isMobile])

  return (
    <div className={s.pillsContainer}>
      <div className={s.pillsWrapper} ref={wrapper}>
        <NavLink
          to="/earn/terms"
          activeClassName={s.activePill}
          className={s.pill}
        >
          Terms of Use
        </NavLink>
        <NavLink
          to="/earn/privacy-policy"
          activeClassName={s.activePill}
          className={s.pill}
        >
          Online Privacy Policy
        </NavLink>
        <NavLink
          to="/earn/raisecashterms"
          activeClassName={s.activePill}
          className={s.pill}
        >
          Raise Cash Terms
        </NavLink>
      </div>
    </div>
  )
}

export default EarnLinks
