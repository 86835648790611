import "./index.scss";
import { Typography } from "components/typography";
import { PARTNER_LIST } from "./utils/partner-list";

export const ParterList = () => (
  <div className="partner-list">
    <div className="container">
      <Typography variant="body-md" className="title">Proudly backed by</Typography>
      {PARTNER_LIST.map(({ name, logo }) => (
        <div className="partner">
          <img src={logo} alt="Partner logo" />
          <Typography variant="body-sm">{name}</Typography>
        </div>
      ))}
    </div>
  </div>
);
