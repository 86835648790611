import "./index.scss";

export const Typography = ({
  tag,
  variant,
  semibold,
  children,
  customClassname = "",
  ...props
}) => {
  const isTitle = variant.includes("title");
  const TagName = tag ?? (isTitle ? "h1" : "p");

  return (
    <TagName
      className={`typography ${variant} ${semibold ? "semibold" : ""} ${customClassname}`}
      {...props}
    >
      {children}
    </TagName>
  );
};
